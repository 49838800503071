import React, { useEffect, useState } from 'react'
import url from '../../assets/sounds/titlemusic.mp3'
import useSound from 'use-sound'
import audioSprite from '../../assets/sounds/audioSprite.mp3'

const useAudio = (url) => {
  const [audio] = useState(new Audio(url))
  const [playing, setPlaying] = useState(false)

  const toggle = () => setPlaying(!playing)

  useEffect(() => {
    audio.volume = 0.1
    audio.loop = true
    playing ? audio.play() : audio.pause()
  }, [playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  return [playing, toggle]
}

const Music = () => {
  const [playing, toggle] = useAudio(url)

  return (
    <div>
      <button onClick={toggle}>{playing ? 'Pause' : 'Play'}</button>
    </div>
  )
}

export default Music
