import React from 'react'
import './style.css'

const ToggleMenu = ({ title, names, toggles, setToggles }) => {
  const setToggle = (position) => {
    const updateState = toggles.map((item, index) => (index === position ? !item : item))
    setToggles(updateState)
  }

  return (
    <>
      <form className="toggle-form">
        <fieldset className="toggle-fieldset">
          <legend className="toggle-legend">{title}</legend>
          {toggles.map((value, index) => {
            return (
              <div key={index} className="toggle">
                {names[index]}
                <label className="switch">
                  <input checked={value} onChange={() => setToggle(index)} type="checkbox" />
                  <span className="toggle-slider" />
                </label>
                <br />
              </div>
            )
          })}
        </fieldset>
      </form>
    </>
  )
}

export default ToggleMenu
