import React from 'react'

import './style.css'

const InputMenu = ({ title, valueTitle, inputValue, setInputValue }) => {
  const handleChange = (event) => {
    setInputValue(event.target.value)
  }

  return (
    <>
      <form className="toggle-form">
        <fieldset className="toggle-fieldset">
          <legend className="toggle-legend">{title}</legend>
          <label className="minmax-label" htmlFor={valueTitle}>
            {valueTitle}
          </label>
          <input id={valueTitle} type="text" value={inputValue} onChange={handleChange} />
        </fieldset>
      </form>
    </>
  )
}

export default InputMenu
