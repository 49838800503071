const Terms = () => {
  const terms = [
    { term: 'Do not use this website for any illegal or unlawful purpose.' },
    { term: 'Do not copy, modify or scrape anything from this website without taking permission from the team' },
    { term: "Don't' collect user data from this website." },
    {
      term: 'Do not attempt to programmatically connect to the site, particularly to the admin areas of the site and webapp, the gasless relayer or database(s)',
    },
    {
      term: 'Do not bully, harm or otherwise threaten any of the players either on the website or through the companion Discord server.',
    },
    {
      term: 'You are responsible for the security of your account, including your private keys and seed phrases. We will never ask you for any of this sensitive data.',
    },
    {
      term: 'We are under no obligation to implement unsolicited ideas and contributions. If we do implement suggestions sent to us via Discord or any other means of communication, we are under no obligation to compensate you and the intellectual property and work product belongs to us.',
    },
    {
      term: "The Ethernal Elves game is designed to be a fun &amp; entertaining and is not to be considered as a financial instrument. You will maximize your experience by playing the game and not focusing on ROI or APY. It is not the intention of the creators for you to profit from trading Ethernal Elves NFT's or ERC20 tokens used in the game universe.",
    },
    {
      term: 'We make no warranties or guarantees on the future value of any of the digital assets you use playing the game.',
    },
    {
      term: "You own the rights to your Sentinel Elf and other NFT's within the Ethernal Elves universe. We continue to own the Copyright to the Elf, and we agree not to license the Elf to anyone else, or to use the Elf in any way inconsistent with your License.",
    },
    {
      term: 'As the creators of the Ethernal Elves, we may earn a royalty on any transfer of Ethernal Elves in perpetuity.',
    },
    {
      term: 'Each Elf is on the Ethereum blockchain. Ownership of the Elf license is mediated entirely by the Smart Contract and the Ethereum Network. The holder of the Wallet associated with the Elf is the holder of the License.',
    },
    { term: 'We may modify these terms of service without prior notice' },
    { term: 'Not complying with the terms of service may result in suspension or termination of your account. ' },
    { term: 'Have as much fun as you possibly can.' },
  ]

  return (
    <div className="h-full flex flex-col p-4 mx-auto max-w-[800px]">
      <h2 className="text-3xl font-bold p-3">Terms of Service &ndash; Our agreement with you</h2>
      <p className="text-lg p-3">By using this website you agree to the following terms of service</p>
      <ul className="list-disc p-3">
        {terms.map((term) => (
          <li key={term.term}>{term.term}</li>
        ))}
      </ul>
    </div>
  )
}

export default Terms
