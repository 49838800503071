const About = () => {
  ///get
  return (
    <div>
      <h1>About</h1>
      <p>This is the about page</p>
      <img
        style={{ imageRendering: 'pixelated' }}
        width={600}
        src="https://huskystudios.ams3.cdn.digitaloceanspaces.com/ethernalElves/rendered/elders/1.png"
        alt="elder"
      />
    </div>
  )
}
export default About
