import React, { useRef, useState, Suspense, useEffect, useCallback } from 'react'
import * as THREE from 'three'
import {
  Environment,
  OrbitControls,
  Stats,
  PerspectiveCamera,
  KeyboardControls,
  Sky,
  useKeyboardControls,
  Stars,
  Plane,
} from '@react-three/drei'
import { Canvas, useFrame, useLoader, extend } from '@react-three/fiber'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { useMemo } from 'react'
import Effects from './Effects'
import Lights from './Lights'
import { PCFSoftShadowMap, sRGBEncoding } from 'three'
import { sortMapData } from '../utils'
import {
  layout,
  layout_flat,
  hexToPixel,
  hex_neighbor,
  hex_round,
  hex_distance,
  calculateCoordinates,
} from '../helpers'

import { placementRules } from '../utils'

import height from './height.png'
import mountains from './mountains.png'

import BeveledHexagonGeometry from './Shapes/BeveledHexagonGeometry'

const ThreeExplore = () => {
  const [map, setMap] = useState([])
  const [textureAtlas, setTextureAtlas] = useState(null)
  const [loading, setLoading] = useState(true)

  const TerrainLayers = ({ terrain }) => {
    const ref = useRef()
    const tileName = terrain.tile

    const tileType = terrain.type
    const height = tileType === 'water' ? -terrain.height : terrain.height
    const texture = textureAtlas[tileName]

    return (
      <mesh ref={ref}>
        <BeveledHexagonGeometry depth={height} />
        <meshStandardMaterial map={texture} transparent={true} />
      </mesh>
    )
  }

  const HexGroup = ({ point }) => {
    //This component renders a hexagon and its terrain layers. On click it terraforms the hexagon and updates the map state.

    const terrains = point.hex.terrain
    const sortedTerrains = terrains.sort((a, b) => a.layer - b.layer)
    const ref = useRef()

    return (
      <group ref={ref} position={[point.x, point.y, point.z]} scale={1}>
        {sortedTerrains.map((terrain, i) => {
          if (terrain.tile === 'clear') {
            return null
          }
          const key = terrain.tile + i
          return <TerrainLayers key={key} terrain={terrain} />
        })}
      </group>
    )
  }

  const formatAndRenderMap = async () => {
    const startPoint = 212

    const n = startPoint - 1
    const s = startPoint + 1
    const e = startPoint + 20
    const w = startPoint - 20
    const ne = n + 20
    const nw = n - 20
    const se = s + 20
    const sw = s - 20

    const segments = [startPoint, n, s, e, w, ne, nw, se, sw]
    const apiBase = 'https://api.ethernalelves.com/api/map/'

    const maps = await Promise.all(
      segments.map(async (segment) => {
        const url = apiBase + segment
        if (segment > 0 && segment <= 1000) {
          const response = await fetch(url)
          const data = await response.json()
          return data
        }
      }),
    )

    //remove undefined values from array
    const filteredMaps = maps.filter((map) => map !== undefined)

    const a = filteredMaps.flat()

    const allMaps = a.map((map) => map.map)

    const map = allMaps.flat()

    //const map = mapRaw //? mapRaw : results.map
    const newMap = []
    const hexagonSize = { x: 1, y: 1 }
    const { mapData, startPos } = sortMapData(map, hexagonSize)
    const origin = { x: -startPos.x, y: -startPos.y }

    for (let i = 0; i < mapData.length; i++) {
      const spacing = 1
      const hexPos = hexToPixel(mapData[i], layout(layout_flat, hexagonSize, origin, spacing))
      newMap.push({ x: hexPos.x, y: -hexPos.y, z: 0, hex: mapData[i] })
    }

    return newMap
  }

  const sortAndLoadTextures = async () => {
    const textureAtlasObject = {}

    const biomeImageData = await fetch('https://api.ethernalelves.com/api/biomes').then((response) => response.json())

    //map through mapImageData and create an array of object where the name is the key and the other data is the value
    biomeImageData.forEach((textureObject) => {
      const texture = new THREE.TextureLoader().load(textureObject.image)
      texture.offset.set(0.5, 0.5)
      texture.repeat.set(0.5, 0.56)
      texture.rotation = 0
      //texture.name.set(textureObject.name)
      const name = textureObject.name
      textureAtlasObject[name] = texture
    })

    return textureAtlasObject
  }

  //console.log(map, mapImageData)

  useEffect(() => {
    setLoading(true)
    async function loadMapAndTextures() {
      const loadedMap = await formatAndRenderMap()
      const allTextures = await sortAndLoadTextures()
      setMap(loadedMap)
      setTextureAtlas(allTextures)
    }
    loadMapAndTextures()
    setLoading(false)
  }, [])

  const MainMapGrid = () => {
    return (
      <group position={[-17, 15, -35]}>
        {map.map((point, i) => {
          return <HexGroup key={i} point={point} hex={point.hex} position={[point.x, point.y, point.z]} />
        })}
      </group>
    )
  }

  return (
    <div className="bg-black h-screen w-screen">
      {!loading && map.length > 0 && (
        <KeyboardControls
          map={[
            { name: 'forward', keys: ['ArrowUp', 'w', 'W'] },
            { name: 'backward', keys: ['ArrowDown', 's', 'S'] },
            { name: 'left', keys: ['ArrowLeft', 'a', 'A'] },
            { name: 'right', keys: ['ArrowRight', 'd', 'D'] },
            { name: 'jump', keys: ['Space'] },
          ]}
        >
          <Canvas
            shadows
            gl={{
              antialias: true,
              toneMappingExposure: 0.5,
              shadowMap: {
                enabled: true,
                type: PCFSoftShadowMap,
              },
              outputEncoding: sRGBEncoding,
            }}
          >
            <PerspectiveCamera makeDefault position={[0, 0, 100]} fov={45} far={1000} near={10} />
            <MainMapGrid />
            <Stats />

            <Lights />

            <Stars radius={16} depth={50} count={2000} factor={4} saturation={0} fade speed={1} />
            <OrbitControls enableRotate={true} enablePan={true} enableZoom={true} />
            <Environment preset="sunset" />
          </Canvas>
        </KeyboardControls>
      )}
    </div>
  )
}

export default ThreeExplore
