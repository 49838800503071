import React, { useState } from 'react'

import Loader from '../../components/Loader'
import SentinelQueryPanel from './SentinelQueryPanel'
import SentinelDisplayPanel from './SentinelDisplayPanel'

const ExploreSentinels = ({}) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadingText = 'Retrieving data...'

  return (
    <div className="explore">
      <div id="left" className="panel">
        <div id="left-wrap" className="panel-wrap">
          <SentinelQueryPanel setData={setData} setIsDataLoading={setIsLoading} />
        </div>
      </div>
      <div id="right" className="panel">
        {isLoading ? <Loader text={loadingText} /> : <SentinelDisplayPanel data={data} />}
      </div>
    </div>
  )
}

export default ExploreSentinels
