import './App.css'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Home from './views/home'
//import Play from './views/play'
//import Profile from './views/profile'
//import Explore from './views/explore'
//import Admin from './views/admin'
import Terms from './wallet/Terms'
import Loader from './components/Loader'
import NavBar from './components/Navigation/NavBar'
import { app, analytics } from './utils/initFirebase'
import backImg from './assets/images/background.png'
import About from './views/about'

import { lazy, Suspense } from 'react'
import RectMap from './views/play/components/Land/Hex/RectangleMap'
import ThreeWorldCanvas from './views/play/components/Land/Three/ThreeWorld'
import ThreeExplore from './views/play/components/Land/Three/ThreeExplore'
import Explore from './views/explore'
import ExploreOld from './views/explore-old'

const Play = lazy(() => import('./views/play'))
const Profile = lazy(() => import('./views/profile'))
const Admin = lazy(() => import('./views/admin'))

function App() {
  const gamePaused = false

  return !gamePaused ? (
    <div className="font-global">
      <div
        className="bg-fixed font-body h-screen bg-bottom bg-cover bg-no-repeat bg-origin-border"
        style={{ backgroundImage: `url(${backImg})` }}
      >
        <div className="bg-[#000000dd] w-screen h-screen">
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <NavBar />
              <Switch>
                <Route exact path="/about">
                  <About />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/play">
                  <Play />
                </Route>
                <Route exact path="/admin">
                  <Admin />
                </Route>
                <Route path="/map">
                  <RectMap />
                </Route>
                <Route path="/world">
                  <ThreeWorldCanvas />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/explore">
                  <Explore />
                </Route>
                <Route path="/explore-old">
                  <ExploreOld />
                </Route>
                <Route
                  exact
                  path="/discord"
                  component={({ location }) => (
                    <Redirect
                      to={{
                        ...location,
                        pathname: location.pathname.replace(/discord/, 'profile/discord'),
                      }}
                    />
                  )}
                />
                <Route exact path="/terms">
                  <Terms />
                </Route>
              </Switch>
            </Suspense>
          </Router>
        </div>
      </div>
    </div>
  ) : (
    <Loader text={'Game Paused'} />
  )
}

export default App
