import { useEffect, useState } from 'react'
import { useMoralis, useChain } from 'react-moralis'
import Moralis from 'moralis-v1'
import { checkChain } from '../utils/interact'
import bear from '../assets/images/bear.png'
import liger from '../assets/images/liger.png'
import PaperPopup from '../components/PaperPopup'
import { PopupHeading } from '../components/PopupHeading'

const Authenticate = () => {
  const { authenticate, isAuthenticated, user, logout, setUserData, enableWeb3 } = useMoralis()

  const [authError, setAuthError] = useState(null)
  const [isAuthenticating, setIsAuthenticating] = useState(false)

  const handleAuth = async (provider) => {
    try {
      setAuthError(null)
      setIsAuthenticating(true)

      // Enable web3 to get user address and chain
      await enableWeb3({ throwOnError: true, provider })
      const { account, chainId } = Moralis

      if (!account) {
        throw new Error('Connecting to chain failed, as no connected account was found')
      }
      if (!chainId) {
        throw new Error('Connecting to chain failed, as no connected chain was found')
      }

      // Get message to sign from the auth api
      const { message } = await Moralis.Cloud.run('requestMessage', {
        address: account,
        chain: parseInt(chainId, 16),
        network: 'evm',
      })

      // Authenticate and login via parse
      const authResponse = await authenticate({
        signingMessage: message,
        throwOnError: true,
      })

      console.log('hardware wallet users:', authResponse)
    } catch (error) {
      setAuthError(error)
      handleMoralisError(error)
    } finally {
      setIsAuthenticating(false)
      setShowModal(false)
    }
  }

  const { switchNetwork } = useChain()

  const [showModal, setShowModal] = useState(false)
  const [currentChain, setCurrentChain] = useState(0)

  function handleMoralisError(err) {
    switch (err.code) {
      case Moralis.Error.INVALID_SESSION_TOKEN:
        Moralis.User.logOut()
        // If web browser, render a log in screen
        // If Express.js, redirect the user to the log in route
        console.log('ok')
        break

      // Other Moralis API errors that you want to explicitly handle
    }
  }

  const switchChains = async () => {
    await Moralis.enableWeb3()
    await switchNetwork(currentChain.requestedChainId)
  }

  const handleLogin = async (action) => {
    if (action === 'logout') {
      await logout()
    }

    setShowModal(false)
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          logout()
        }
      })
    }
  }

  useEffect(() => {
    addWalletListener()
  }, [])

  useEffect(() => {
    const getChain = async () => {
      let chainData = await checkChain('ethereum')
      setCurrentChain(chainData) // !== mainnet ? setChainModal(true) : setChainModal(false)
    }
    getChain()
  }, [window?.ethereum?.chainId])

  return (
    <>
      <button className="btn bg-curlyButton w-48 md:w-72 text-xs md:text-base" onClick={() => setShowModal(true)}>
        {!isAuthenticated ? 'Log In' : `Logout ...${String(user.get('ethAddress')).substring(38)}`}
      </button>
      <PaperPopup show={showModal} setShow={setShowModal} width={400}>
        {authError && (
          <div className="flex flex-col items-center">
            <PopupHeading title={'Error'} />
            <span>{authError.message}</span>
            <button className="btn bg-longButtonA" onClick={() => setAuthError(null)}>
              close
            </button>
          </div>
        )}
        {!currentChain.chainIdBool ? (
          <>
            <div className="flex flex-col items-center">
              <PopupHeading title={'WRONG CHAIN DETECTED'} subtitle={'Please switch to Ethereum Mainnet'} />
              <button className="btn bg-longButtonA" onClick={switchChains}>
                switch chains
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center align-center items-center">
              {isAuthenticated ? (
                <div className="flex flex-col items-center">
                  <PopupHeading title={'Logged In'} subtitle={'Please confirm logout'} />
                  <img src={liger} alt="liger" />
                  <div className="p-6">
                    <button className="btn bg-curlyButton w-64" onClick={() => handleLogin('logout')}>
                      Confirm Logout
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-center py-4">
                    <img src={bear} alt="bear" />
                    <div className="p-2 mt-5 bg-slate-700 border-2 border-white rounded-lg md:text-base text-xs">
                      {window.ethereum?.selectedAddress}
                    </div>
                  </div>

                  <button className="btn bg-curlyButton w-64" onClick={() => handleAuth('metamask')}>
                    Connect Wallet
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </PaperPopup>
    </>
  )
}

export default Authenticate
