import { useHelper } from '@react-three/drei'
import { useRef } from 'react'
import { DirectionalLightHelper } from 'three'

export default function Lights() {
  const ref = useRef()

  //useHelper(ref, DirectionalLightHelper);

  return (
    <group>
      <hemisphereLight
        args={[
          'white', //
          'darkslategrey',
          2,
        ]}
      />
      <directionalLight
        ref={ref}
        castShadow //
        position={[10, 0, 20]}
        intensity={0.1}
        color="green"
      />

      <directionalLight
        position={[6, 67, 34]} //
        intensity={0.1}
      />
    </group>
  )
}
