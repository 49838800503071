import React from 'react'

const WeeklyBurn = ({ weeklyRen, weeklyMoon }) => {
  if (!weeklyRen) {
    return <div>Fetching data...</div>
  } else {
    const totalRenBurn = new Intl.NumberFormat('en-US').format(Math.floor(weeklyRen[weeklyRen.length - 1]?.burn))
    const totalMoonBurn = new Intl.NumberFormat('en-US').format(Math.floor(weeklyMoon[weeklyMoon.length - 1]?.burn))
    return (
      <>
        <div className="LBcontainer bg-paper bg-[length:100%_100%] max-w-xl">
          <div className="top5">Ren burn</div>
          {weeklyRen.map((lb, key) => {
            const burn = Math.floor(Number(lb.burn))
            const result = new Intl.NumberFormat('en-US').format(burn)
            if (lb.objectId === 'Total') {
              return
            } else {
              return (
                <div key={key} className="LBtext ">
                  {!lb.burn ? (
                    <div>{`${lb.objectId}`}</div>
                  ) : (
                    <div>
                      {`${lb.objectId}: `}
                      <span className={`${burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
                        burn < 0 ? '' : '+'
                      }${result} ren `}</span>
                    </div>
                  )}
                </div>
              )
            }
          })}
          <div className="font-bold">
            {`Total REN burned this week: `}{' '}
            <span className={`${weeklyRen[weeklyRen.length - 1]?.burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
              weeklyRen[weeklyRen.length - 1]?.burn > 0 ? '+' : ''
            }${!weeklyRen[0].burn ? 0 : totalRenBurn}`}</span>
          </div>
        </div>
        <div className="LBcontainer bg-paper bg-[length:100%_100%] max-w-xl">
          <div className="top5">Moon burn</div>
          {weeklyMoon.map((lb, key) => {
            const burn = Math.floor(Number(lb.burn))
            const result = new Intl.NumberFormat('en-US').format(burn)

            if (lb.objectId === 'Total') {
              return
            } else {
              return (
                <div key={key} className="LBtext">
                  {!lb.burn ? (
                    <div>{`${lb.objectId}`}</div>
                  ) : (
                    <div>
                      {`${lb.objectId}: `}
                      <span className={`${burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
                        burn < 0 ? '' : '+'
                      }${result} moon `}</span>
                    </div>
                  )}
                </div>
              )
            }
          })}
          <div className="font-bold">
            {`Total MOON burned this week: `}
            <span className={`${weeklyMoon[weeklyMoon.length - 1]?.burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
              weeklyMoon[weeklyMoon.length - 1]?.burn > 0 ? '+' : ''
            }${!weeklyMoon[0].burn ? 0 : totalMoonBurn}`}</span>
          </div>
        </div>
      </>
    )
  }
}

export default WeeklyBurn
