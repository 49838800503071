import create from 'zustand'
import produce from 'immer'

const water = '#5EBFB5'
export const colorMap = [
  'd4e09b',
  'f6f4d2',
  'cbdfbd',
  'f19c79',
  'a44a3f',
  '6699cc',
  'ff8c42',
  'ff3c38',
  'a23e48',
  'cc998d',
]

export default create((set) => ({
  colors: {
    Water: {
      value: 0.21,
      color: '#00a9ff',
    },
    Shore: {
      value: 0.01,
      color: '#ffd68f',
    },
    Beach: {
      value: 0.04,
      color: '#efb28f',
    },
    Shrub: {
      value: 0.1,
      color: '#9ea667',
    },
    Forest: {
      value: 0.29,
      color: '#586647',
    },
    Stone: {
      value: 0.36,
      color: '#656565',
    },
    Snow: {
      value: 0.6,
      color: '#9aa7ad',
    },
    SnowWhite: {
      value: 0.22,
      color: '#ffffff',
    },
  },

  setColorValue: (key, value) =>
    set(
      produce((state) => {
        state.colors[key].value = value
      }),
    ),
  setColor: (key, color) =>
    set(
      produce((state) => {
        state.colors[key].color = color
      }),
    ),

  generation: {
    Seed: Math.random(),
    Height: 1,
    Scale: 1,
    Detail: 0.5,
    Fuzzyness: 0.25,
    // Contrast: 0.5,
  },

  setGeneration: (key, value) =>
    set(
      produce((state) => {
        state.generation[key] = value
      }),
    ),

  general: {
    Trees: false,
    Grass: false,
    Clouds: false,
  },
  setGeneral: (key, value) =>
    set(
      produce((state) => {
        state.general[key] = value
      }),
    ),
}))
