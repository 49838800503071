import React from 'react'

import './style.css'

const MinMaxMenu = ({ title, min, max, minMax, setMinMax }) => {
  const setMin = (event) => {
    var value = parseInt(event.target.value)
    if (value < min) {
      value = min
    }
    if (value > max) {
      value = max
    }

    setMinMax({
      ...minMax,
      min: value,
    })
  }

  const setMax = (event) => {
    var value = parseInt(event.target.value)
    if (value < min) {
      value = min
    }
    if (value > max) {
      value = max
    }

    setMinMax({
      ...minMax,
      max: value,
    })
  }

  return (
    <>
      <form className="toggle-form">
        <fieldset className="toggle-fieldset">
          <legend className="toggle-legend">{title}</legend>
          <label className="minmax-label" htmlFor="min">
            {' '}
            Min{' '}
          </label>
          <input type="number" id="min" min={min} max={max} value={minMax.min} onChange={setMin} />
          <label className="minmax-label" htmlFor="max">
            {' '}
            Max{' '}
          </label>
          <input type="number" id="max" min={min} max={max} value={minMax.max} onChange={setMax} />
        </fieldset>
      </form>
    </>
  )
}

export default MinMaxMenu
