import React, { useRef, useState, Suspense, useEffect, useCallback } from 'react'
import { Canvas, useFrame, useLoader, extend } from '@react-three/fiber'
import * as THREE from 'three'
import { Environment, OrbitControls, Stats, PerspectiveCamera } from '@react-three/drei'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import Lights from './Lights'
import { PCFSoftShadowMap, sRGBEncoding } from 'three'
import { colorMap } from './state/appState'
import { Color } from 'three'
import textureImage from './1.png'
import nine from '../../../../../assets/fonts/nine.json'

extend({ TextGeometry })
const font = new FontLoader().parse(nine)
const Text = ({ children, position, height = 1, size = 1, color = '#000000', ...props }) => {
  return (
    <mesh position={position}>
      <textGeometry args={[children, { font, size: size, height: height }]} />
      <meshPhongMaterial
        color={color}
        shadowSide={THREE.FrontSide} //
        side={THREE.FrontSide} //
      />
    </mesh>
  )
}

function Sphere() {
  const radius = 80
  const widthSegments = 50
  const heightSegments = 20
  const sphereRef = useRef()

  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  useFrame(() => {
    sphereRef.current.rotation.y += 0.003
  })

  const texture = useLoader(THREE.TextureLoader, textureImage)

  return (
    <mesh
      ref={sphereRef}
      scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
    >
      <sphereGeometry args={[radius, widthSegments, heightSegments]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  )
}

const ThreeWorldCanvas = ({ handleClickBack }) => {
  const [map, setMap] = useState(null)
  const [mapData, setMapData] = useState([])
  const [loading, setLoading] = useState(true)
  const [plotData, setPlotData] = useState(null)

  const handlePlotClick = async (plot) => {
    /*const maps = await fetch(`https://api.ethernalelves.com/api/settlements/${plot.tokenId}`)
      .then((response) => response.json())
      .then((data) => data)*/
    setPlotData(plot)
  }

  const PlotDetails = ({ plot }) => {
    console.log(plot)
    return (
      <group position={[-800, 300, 0]}>
        <Text height={0.1} size={45} color="green" position={[0, -50, 0]}>
          {`Token Id: ${plot.tokenId}`}
        </Text>
        <Text height={0.1} size={45} color="red" position={[0, -100, 0]}>
          {plot.owner}
        </Text>
        {plot.discordUser || plot.ensName ? (
          <Text height={0.1} size={45} color="white" position={[0, -150, 0]}>
            {plot.discordUser || plot.ensName}
          </Text>
        ) : null}
      </group>
    )
  }

  const RectangleMesh = ({ plot }) => {
    const meshRef = useRef()
    const tokenId = parseInt(plot.tokenId)

    let col = Math.floor(tokenId - 1) / 20
    let position = [plot.x, plot.y, 0]
    let rotation = [0, 0, 0]

    let textPosition = [plot.x - 7, plot.y - 5, 14]

    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    let ownerColor = new Color(plot.color)
    let textColor = 'black'
    const invertColorArray = ['#a44a3f', '#6699cc', '#ff3c38', '#a23e48']

    if (invertColorArray.includes(plot.color)) {
      textColor = 'white'
    }

    return (
      <>
        <mesh
          ref={meshRef}
          position={position}
          scale={hovered ? 1.2 : 0.96}
          onClick={(e) => handlePlotClick(plot)}
          rotation={rotation}
          //onPointerOver={(e) => setHover(true)}
          //onPointerOut={(e) => setHover(false)}
        >
          <boxGeometry args={[32, 18, 30]} />

          <meshPhongMaterial
            shadowSide={THREE.FrontSide} //
            side={THREE.FrontSide} //
            color={ownerColor}
          />
        </mesh>
        <mesh position={textPosition}>
          <textGeometry args={[`${plot.tokenId}`, { font, size: 10, height: 1 }]} />
          <meshPhongMaterial color={textColor} shadowSide={THREE.FrontSide} shininess={100} />
        </mesh>
      </>
    )
  }

  const getMapFromMoralis = async () => {
    const cacheBuster = Math.floor(Math.random() * 1000000000)
    const maps = await fetch(`https://api.ethernalelves.com/api/world/?cb=${cacheBuster}`)
      .then((response) => response.json())
      .then((data) => data)
    //loop through maps.mapArray and create a distinct list of owners and count the frequency of each owner

    //list out 100 colors in a rgb gradient in 0x format

    const owners = maps.map((item) => item.owner)
    const uniqueOwners = [...new Set(owners)]
    const ownerCounts = uniqueOwners.map((owner, i) => {
      const colorIndex = i % 10
      return {
        owner: owner,
        count: owners.filter((x) => x === owner).length,
        color: '#' + colorMap[colorIndex],
      }
    })
    //sort the ownerCounts array by count
    const sortedOwnerCounts = ownerCounts.sort((a, b) => b.count - a.count)

    //loop through the mapArray and assign each hex a color based on the ownerCounts array
    const mapArray = maps.map((item) => {
      const owner = sortedOwnerCounts.find((x) => x.owner === item.owner)
      return {
        ...item,
        color: owner.color,
      }
    })
    setMap(mapArray)
  }

  useEffect(() => {
    getMapFromMoralis()
  }, [])

  return (
    <div className="h-screen w-screen flex flex-col items-center">
      <div className="text-4xl text-white font-app">Land Explorer</div>
      <div className="text-sm text-white font-app">
        Use your mouse scroll to zoom in, right click to pan and click to tilt. Click on a tile to see more details
      </div>
      {map && map?.length > 0 && (
        <div className="w-screen h-[600px]">
          <Canvas
            shadows
            gl={{
              antialias: true,
              toneMappingExposure: 0.5,
              shadowMap: {
                enabled: true,
                type: PCFSoftShadowMap,
              },
              outputEncoding: sRGBEncoding,
            }}
          >
            <PerspectiveCamera makeDefault position={[0, 0, 1000]} />
            <group position={[700, 250, 0]}>
              <Sphere />
            </group>
            <group position={[-800, 100, 0]}>
              {map.map((item) => (
                <RectangleMesh plot={item} />
              ))}
            </group>
            {plotData && <PlotDetails plot={plotData} />}

            <Lights />

            
            <OrbitControls enablePan={true} enableZoom={true} />
            <Stats />
          </Canvas>
        </div>
      )}
      <div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleClickBack}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default ThreeWorldCanvas
