import mount from './assets/images/mount.png'
import woods from './assets/images/woods.png'
import meadows from './assets/images/meadows.png'
import campaignImg from './assets/images/campaign.png'
import stakeImg from './assets/images/stake.png'
import bloodImg from './assets/images/bloodthirst.png'
import cove from './assets/images/cove.png'
import mammon from './assets/images/mammon.png'
import fallout from './assets/images/fallout.png'

import hidden from './assets/images/hidden.png'
import hollow from './assets/images/hollow.png'
import souls from './assets/images/souls.png'
import tomb from './assets/images/tomb.png'
import darkuntamed from './assets/images/darkuntamed.png'
import inferno from './assets/images/inferno.png'
import untamed from './assets/images/untamed.png'

import AuraOfImmunity from './assets/images/items/Aura_of_Immunity.png'
import DemonicRupture from './assets/images/items/Demonic_Rupture.png'
import MidasRing from './assets/images/items/Midas_Ring.png'
import MoonElixir from './assets/images/items/Moon_Elixir.png'
import SpiritBand from './assets/images/items/Spirit_Band.png'
import TalismanOfEnragement from './assets/images/items/Talisman_of_Enragement.png'

import bloodthirstIcon from './assets/images/icons/bloodthirst.png'
import buyIcon from './assets/images/icons/Buy@0.5x.png'
import campaignIcon from './assets/images/icons/Campaign@0.5x.png'
import crusadeIcon from './assets/images/icons/ethernal_elves_crusade.png'
import healingIcon from './assets/images/icons/heal.png'
import passiveIcon from './assets/images/icons/Passive@0.5x.png'
import rampageIcon from './assets/images/icons/rampage.png'
import rerollitemIcon from './assets/images/icons/Re-Roll-Item@0.5x.png'
import rerollWeaponIcon from './assets/images/icons/Re-roll-Weapon@0.5x.png'
import returnPassiveIcon from './assets/images/icons/ReturnPassive@0.5x.png'
import sellIcon from './assets/images/icons/Sell@0.5x.png'
import synergizeIcon from './assets/images/icons/synergize.png'
import thevoid from './assets/images/icons/void.png'
import eldersIcon from './assets/images/elders.png'
import sacredWell from './assets/images/icons/sacredWell.png'
import trainingIcon from './assets/images/icons/training.png'
import elderCrusade from './assets/images/icons/elderCrusade.png'
import crafting from './assets/images/icons/crafting.png'

import divine from './assets/images/vaults/devine.png'

import zBearGui from './assets/images/bear.png'
import zLigerGui from './assets/images/liger.png'

export const sentinelClass = ['Druid', 'Assassin', 'Ranger']

export const actions = [
  { id: 0, text: 'passive', image: stakeImg },
  { id: 1, text: 'campaign', image: campaignImg },
  { id: 2, text: 'bloodThirst', image: bloodImg },
]

export const actionString = [
  {
    action: 0,
    text: 'unstake',
    description: 'Unstake your Elf and send it back to your wallet',
    instructions: [''],
    image: eldersIcon,
  },
  {
    action: 1,
    text: 'stake',
    description: 'Stake your Elf with the game contract.',
    instructions: ['Your elf needs to be staked before you can play Ethernal Elves'],
    image: thevoid,
  },
  { action: 2, text: 'campaign', image: campaignIcon },
  {
    action: 3,
    description: 'Send your sentinels on a long journey in search of the MIREN they so desire',
    instructions: [
      'Enter passive mode and earn daily rewards for keeping your elf staked. Rewards accumulate daily.',
      'You need to come back here and return from passive mode to get the REN reward.',
    ],
    text: 'passive mode',
    image: passiveIcon,
  },
  { action: 4, text: 'return', image: returnPassiveIcon },
  {
    action: 5,
    description: 'Forge a new weapon for your sentinels',
    instructions: [
      ' there is 20% chance you will get a higher tier weapon, 10% chance you will get downgraded and 70% chance you get a different weapon within the same tier. ',
      'you cannot roll T4 & T5 weapons',
    ],
    text: 're-roll weapon',
    image: rerollWeaponIcon,
  },
  {
    action: 6,
    description: 'Roll for a new item. There is 20% chance to get a new item..',
    instructions: [''],
    text: 're-roll item',
    image: rerollitemIcon,
  },
  {
    action: 7,
    description: 'Use the magical properties of your Druid to heal sentinels currently on the battlefield',
    instructions: [
      'Druids can heal their sentinels by using their magical properties. This will heal all sentinels on the battlefield.',
      ' This will not heal sentinels that are currently in passive mode or in crusades.',
    ],
    text: 'healing',
    image: healingIcon,
  },
  {
    action: 8,
    description: 'Use the Prism Bridge to send your sentinels to polygon.',
    instructions: [
      'Select the sentinels you wish to transfer between chains and initiate the transfer.',
      'You will need come back to this screen to confirm the transfer. transfers take between 3-10 minutes',
    ],
    text: 'polygon',
    image: thevoid,
  },
  {
    action: 9,
    description: 'Attempt to synergize your druid and reduce its heal cooldown.',
    instructions: [
      'there is 10% chance you will reduce your druid cooldown by 50% and a 60% chance to reduce cooldown by 33%.',
      'There is a 30% chance you will get a 5 minute penalty. Will not work on Druids currently on Crusades',
    ],
    text: 'synergize',
    image: synergizeIcon,
  },
  {
    action: 10,
    description: 'Battle the bloodthirst creature and reap the rewards',
    instructions: [
      'You need at least a Tier 3 weapon to Bloodthirst.',
      'Your recovery depends on your attack points and health points',
    ],
    text: 'bloodthirst',
    image: bloodthirstIcon,
  },
  {
    action: 11,
    description: 'Go on a Rampage to earn experience points (levels)',
    instructions: [
      'Increase your Sentinels level and health points by going on a rampage.',
      'You can only do this once every 36 hours',
    ],
    text: 'rampage',
    image: rampageIcon,
  },
  { action: 12, text: 'buy item', image: buyIcon },
  { action: 13, text: 'sell item', image: sellIcon },
  {
    action: 14,
    description: 'Use the scrolls you acquired to go on a Crusade to acquire artifacts',
    instructions: [
      'You need to go on a Crusade before you can awaken the elders with Artifacts.',
      'You can use moon to decrease the cost of a Crusade, or increase the probability of finding more than one artifact',
    ],
    text: 'crusade',
    image: crusadeIcon,
  },
  { action: 15, text: 'return crusade', image: returnPassiveIcon },
  {
    action: 16,
    description: 'Locate missing Elf',
    instructions: [
      'If your recently transferred Sentinel is missing, you can locate it by typing in the token Id here.',
    ],
    text: 'Lookup',
    image: crusadeIcon,
  },
  {
    action: 17,
    description: 'Mint Elder',
    instructions: ['Use artifacts collected in game to awaken the elders'],
    text: 'Mint Elder',
    image: eldersIcon,
  },
  {
    action: 18,
    description: 'Heal at the sacred well',
    instructions: [
      'Elders have certain Energy limitations which will need a specific amount prior to completing game actions/functions.',
      'They can replenish their Energy by going to the Sacred Well.',
    ],
    text: 'sacred well',
    image: sacredWell,
  },
  {
    action: 19,
    description: 'Level up and train your Elders',
    instructions: [
      'The Training Grounds is an area will you will be able to level up your Elder.',
      'Each level gain, will increase a specific Base Value (Stats) such as Strength, Agility or Intelligence. Whenever you enter a specific area in the Training Ground, you will use a specific amount of Energy based on your Energy Tier.',
      'See below for the Training Ground Areas and Energy Use Table',
    ],
    text: 'training grounds',
    image: trainingIcon,
  },
  {
    action: 20,
    description: 'Elders will go on Crusades to receive the following Resources',
    instructions: [
      'Elders will go on Crusades to receive the following Resources:',
      'Aether',
      'Iron',
      'Frost',
      'Terra',
      'Magma',
      'Artifacts',
    ],
    text: 'crusades',
    image: elderCrusade,
  },
  {
    action: 21,
    description: 'Open a vault to receive your resources',
    instructions: ['Common', 'Rare', 'Legendary', 'Ancient', 'Divine', 'Artifacts (20% chance 35% with moon)'],
    text: 'opened vault',
    image: divine,
  },
  {
    action: 22,
    description: 'Crafting',
    instructions: ['Common', 'Rare', 'Legendary', 'Ancient', 'Divine', 'Artifacts (20% chance 35% with moon)'],
    text: 'crafting',
    image: crafting,
  },
  {
    action: 23,
    description: 'Boss Battle',
    instructions: ['Common', 'Rare', 'Legendary', 'Ancient', 'Divine', 'Artifacts (20% chance 35% with moon)'],
    text: 'boss battle',
    image: crafting,
  },
  {
    action: 24,
    description: 'Farming',
    instructions: ['Passively earn ZUG by Farming'],
    text: 'Farming',
    image: rampageIcon,
  },
]

export const rollCosts = [
  { action: 'forge', ren: 200, eth: 0.04 },
  { action: 'merchant', ren: 10, eth: 0.01 },
  { action: 'synergize', ren: 5, eth: 0 },
]

export const items = [
  {
    item: 0,
    text: 'Empty',
    description: '',
  },
  {
    item: 1,
    text: 'Talisman of Enragement',
    description: 'Increase total attack points 2x',
    image: TalismanOfEnragement,
  },
  {
    item: 2,
    text: 'Moon Elixir',
    description: 'Increase health points by 2x',
    image: MoonElixir,
  },
  {
    item: 3,
    text: 'Midas Ring',
    description: 'Double Rewards',
    image: MidasRing,
  },
  {
    item: 4,
    text: 'Spirit Band',
    description: 'Double XP (level)',
    image: SpiritBand,
  },
  {
    item: 5,
    text: 'Aura of Immunity',
    description: 'Eliminates HP Regeneration',
    image: AuraOfImmunity,
  },
  {
    item: 6,
    text: 'Demonic Rupture',
    description: 'Increase attack points by 3x',
    image: DemonicRupture,
  },
]

export const campaigns = [
  {
    id: 1,
    name: 'Whispering Woods',
    image: woods,
    time: 1.2,
    maxLevel: 100,
  },
  {
    id: 2,
    name: 'Enchanted Meadows',
    image: meadows,
    time: 1.5,
    maxLevel: 100,
  },
  {
    id: 3,
    name: 'Mount Eluna',
    image: mount,
    time: 0.6,
    maxLevel: 100,
  },
  {
    id: 4,
    name: 'Northern Fallout',
    image: fallout,
    time: 0.6,
    maxLevel: 30,
  },
  {
    id: 5,
    name: 'Cove of Samim',
    image: cove,
    time: 0.6,
    maxLevel: 50,
  },
  {
    id: 6,
    name: "Mammon's Lair",
    image: mammon,
    time: 0.6,
    maxLevel: 100,
  },
]

export const rampages = [
  {
    id: 1,
    name: 'Wandering Souls',
    image: souls,
  },
  {
    id: 2,
    name: 'Fervorous Hollow',
    image: hollow,
  },
  {
    id: 3,
    name: 'Hidden Grove',
    image: hidden,
  },
  {
    id: 4,
    name: 'Tomb of Vitality',
    image: tomb,
  },
  {
    id: 5,
    name: 'Inferno Abyss  ',
    image: inferno,
  },
  {
    id: 6,
    name: 'Untamed Ether',
    image: untamed,
  },
  {
    id: 7,
    name: ' Dark Untamed Ether',
    image: darkuntamed,
  },
]

export const accessoriesAbilities = [
  {
    id: 2,
    name: 'Bear',
    ability: 'increases attack points by 30+',
    tier: 4,
    image: zBearGui,
  },
  {
    id: 3,
    name: 'Liger',
    ability: 'decreases druid heal cooldown to 4 hours',
    tier: 4,
    image: zLigerGui,
  },
  {
    id: 4,
    name: 'None',
    ability: '',
    tier: 0,
  },
  {
    id: 5,
    name: 'Claws',
    ability: 'cosmetic',
    tier: 1,
  },
  {
    id: 6,
    name: 'Drus Ruler of The Oaks',
    ability: 'decreases druid heal cooldown to 4 hours and increases attack points by 20+',
    tier: 5,
  },
  {
    id: 8,
    name: 'Necklace',
    ability: 'cosmetic',
    tier: 1,
  },
  {
    id: 9,
    name: 'Necklace & Armband',
    ability: 'cosmetic',
    tier: 2,
  },
  {
    id: 10,
    name: 'Crown of Dahagon',
    ability: 'Increases Instant Kill probability by 15%',
    tier: 3,
  },
  {
    id: 11,
    name: "Mechadon's Vizard",
    ability: 'Increases Instant Kill probability by 25%',
    tier: 4,
  },
  {
    id: 12,
    name: 'Euriel The Protector',
    ability: 'Increases Instant Kill probability by 35%',
    tier: 5,
  },
  {
    id: 13,
    name: 'Kidor The Slayer of Demons',
    ability: 'Increases Instant Kill probability by 35%',
    tier: 5,
  },
  {
    id: 14,
    name: 'Lord Mecha Aker',
    ability: 'Increases Instant Kill probability by 35%',
    tier: 5,
  },
  {
    id: 15,
    name: 'Wristband',
    ability: 'cosmetic',
    tier: 1,
  },
  {
    id: 16,
    name: 'Wristband & Necklace',
    ability: 'cosmetic',
    tier: 2,
  },
  {
    id: 17,
    name: "Azrael's Crest",
    ability: 'Increases attack points by 15%',
    tier: 3,
  },
  {
    id: 18,
    name: 'El Machina',
    ability: 'Increases attack points by 25%',
    tier: 4,
  },
  {
    id: 19,
    name: 'Eriel Angel of Nature',
    ability: 'Increases attack points by 35%',
    tier: 5,
  },
  {
    id: 20,
    name: 'Khonsuna Demon Destroyer',
    ability: 'Increases attack points by 35%',
    tier: 5,
  },
  {
    id: 21,
    name: 'Lord Machina Ethena',
    ability: 'Increases attack points by 35%',
    tier: 5,
  },
]

export const dailyRenRewards = [
  {
    level: '1 - 20',
    weaponTier1: '10',
    weaponTier2: '12',
    weaponTier3: '14',
    weaponTier4: '18',
    weaponTier5: '24',
  },
  {
    level: '21 - 40',
    weaponTier1: '11',
    weaponTier2: '13.2',
    weaponTier3: '15.4',
    weaponTier4: '19.8',
    weaponTier5: '26.4',
  },
  {
    level: '41 - 60',
    weaponTier1: '12.5',
    weaponTier2: '15',
    weaponTier3: '17.5',
    weaponTier4: '22.5',
    weaponTier5: '30',
  },
  {
    level: '61 - 80',
    weaponTier1: '13',
    weaponTier2: '15.6',
    weaponTier3: '18.2',
    weaponTier4: '23.4',
    weaponTier5: '31.2',
  },
  {
    level: '81 - 100',
    weaponTier1: '15',
    weaponTier2: '18',
    weaponTier3: '21',
    weaponTier4: '27',
    weaponTier5: '36',
  },
]
