import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import next from '../../assets/images/buttons/next.png'
import prev from '../../assets/images/buttons/prev.png'

function WeeklyLeaderboard({ renLB, moonLB, bossLB, handleClickBack, playersArr }) {
  return (
    <>
      <h2 className=" text-xl text-center font-bold flex justify-center md:text-2xl lg:text-3xl bg-no-repeat bg-[length:100%_100%] w-[70%] md:w-1/2 lg:w-[38%] bg-curlyButton mb-2">
        Weekly Leaderboard
      </h2>
      <div className="leaderboard flex-wrap">
        <div className="LBcontainer bg-paper bg-[length:100%_100%] max-w-3xl">
          <div className="top5">Ren burn</div>
          {renLB.map((lb, key) => {
            key = key + 1
            let fire
            if (key === 1) {
              fire = '🔥'
            } else {
              fire = ''
            }
            const result = new Intl.NumberFormat('en-US').format(lb.tokenBurn)

            return (
              <div key={key} className="LBtext">
                {!lb.tokenBurn ? (
                  <div>{`${lb.objectId}`}</div>
                ) : (
                  <div className="font-bold break-all">
                    {`${fire}${key}. ${lb.objectId}: `} <span className="text-red-800">{`${result} ren ${fire}`}</span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className="LBcontainer bg-paper bg-[length:100%_100%] max-w-3xl">
          <div className="top5">Moon burn</div>
          {moonLB.map((lb, key) => {
            key = key + 1
            let fire
            if (key === 1) {
              fire = '🔥'
            } else {
              fire = ''
            }
            const result = new Intl.NumberFormat('en-US').format(lb.tokenBurn)
            return (
              <div key={key} className="LBtext">
                {!lb.tokenBurn ? (
                  <div>{`${lb.objectId}`}</div>
                ) : (
                  <div className="font-bold break-all">
                    {`${fire}${key}. ${lb.objectId}: `} <span className="text-red-800">{`${result} moon ${fire}`}</span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className="LBcontainer bg-paper bg-[length:100%_100%] max-w-3xl">
          <div className="top5">Boss kills</div>
          {bossLB.map((lb, key) => {
            key = key + 1
            let fire
            if (key === 1) {
              fire = '🔥'
            } else {
              fire = ''
            }
            return (
              <div key={key} className="LBtext">
                {!lb.playerWin ? (
                  <div>{`${lb.objectId}`}</div>
                ) : (
                  <div className="font-bold break-all">
                    {`${fire}${key}. ${lb.objectId}: `}{' '}
                    <span className="text-green-400">{` ${lb.playerWin} ${
                      lb.playerWin > 1 ? 'kills' : 'kill'
                    }${fire}`}</span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <h2 className=" text-xl text-center font-bold flex justify-center md:text-2xl lg:text-3xl bg-no-repeat bg-[length:100%_100%] w-[70%] md:w-1/2 lg:w-[38%] bg-curlyButton mb-2">
        Sacrifice Leaderboard
      </h2>
      <div className="leaderboard">
        <Carousel
          className="LBcontainer bg-paper bg-[length:100%_100%] max-w-[500px] break-all justify-center items-center"
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          renderArrowNext={(onNext, hasNext, label) =>
            hasNext && (
              <button className="control-arrow right-0 flex flex-col items-center justify-center" onClick={onNext}>
                <img className="max-w-[30px]" src={next} alt="next arrow" />
              </button>
            )
          }
          renderArrowPrev={(onPrev, hasPrev, label) =>
            hasPrev && (
              <button className="control-arrow left-0 flex flex-col items-center justify-center" onClick={onPrev}>
                <img className="max-w-[30px]" src={prev} alt="prev arrow" />
              </button>
            )
          }
        >
          {playersArr.map((players, key) => {
            key += 0
            return (
              <section key={key}>
                {players.map((player, key2) => {
                  if (key === 0) {
                    key2 += 1
                  } else {
                    key2 = key2 + key * 5 + 1
                  }
                  const result = new Intl.NumberFormat('en-US').format(player.total)
                  return (
                    <div key={key2} className="LBtext">
                      {!player.objectId ? (
                        <div>Fetching stats...</div>
                      ) : (
                        <div className="font-bold break-all">
                          {`${key2}. ${player.objectId}: `} <span className="text-red-700">{result}</span>
                        </div>
                      )}
                    </div>
                  )
                })}
              </section>
            )
          })}
        </Carousel>
      </div>
      <div className="flex justify-center">
        <button className="btn--back btn-menu mr-6 md:mr-0 text-xl md:text-2xl" onClick={handleClickBack}>
          Back
        </button>
      </div>
    </>
  )
}

export default WeeklyLeaderboard
