import React from 'react'

const WalletSearch = ({
  hiddenHelp,
  handleCloseHelp,
  handleEnterAddress,
  ens,
  handleSubmit,
  isEmpty,
  handleOpenHelp,
  handleClickBack,
}) => {
  return (
    <>
      <div className={`${hiddenHelp} text-xl lg:text-2xl font-bold text-center`} id="help">
        <button className="close-help" onClick={handleCloseHelp}>
          &times;
        </button>
        <h2>If you don't have or don't know any addresses you can use this one:</h2>
        <h3>0xE4de66EF0e34829356b53FB2C0Aa17873798e7Be</h3>
      </div>
      <div className={hiddenHelp} id="overlay"></div>
      <div className="first text-center">
        <h1 id="top" className="text-3xl lg:text-5xl font-bold">
          Look up a wallet
        </h1>
        <div className="initial">
          <div className="border-solid border-white pr-1 border-2 text-md md:text-lg flex justify-end items-end	h-[70px] w-[350px] md:w-[400px]">
            Enter wallet or ENS
          </div>
          <input
            maxLength={42}
            className="searchbar"
            value={ens}
            onChange={(e) => handleEnterAddress(e.target.value)}
          />
          <div className="h-24">
            <button className="search btn-menu text-2xl" onClick={handleSubmit} disabled={isEmpty}>
              Search
            </button>
            <button className="btn--back btn-menu text-2xl" onClick={handleClickBack}>
              Back
            </button>
            <button className="btn--help" onClick={handleOpenHelp}>
              ?
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default WalletSearch
