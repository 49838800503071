import Authenticate from '../../wallet/Authenticate'
import logoImg from '../../assets/images/logo.png'
import SideNav from './Sidenav'
import Music from '../Audio'

const NavBar = () => {
  return (
    <>
      <div className="p-4 mb-8 md:mb-2 h-12 md:h-24 flex flex-col items-center w-screen justify-center">
        {process.env.REACT_APP_PROD_ENV === 'development' && (
          <span className="text-xs">{process.env.REACT_APP_MORALIS_TESTNET_URL}</span>
        )}
        <img className="hidden md:block" src={logoImg} alt="logo" />
      </div>
      <div className="absolute top-4 left-4 md:top-8 md:left-8">
        <SideNav />
      </div>
      <div className="absolute top-4 right-0 md:top-8 md:right-4">
        <Authenticate />
      </div>
    </>
  )
}
export default NavBar
