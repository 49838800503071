import React, { useEffect, useState, useCallback } from 'react'
import AlertBar from '../../components/Alert'
import useSound from 'use-sound'
import audioSprite from '../../assets/sounds/audioSprite.mp3'
import titleMusic from '../../assets/sounds/titlemusic.mp3'
import ExploreWallet from './exploreWallet'
import WeeklyLeaderboard from './WeeklyLeaderboard'
import Burn from './Burn'
import RenEconomyCharts from './RenEconomyCharts'
import './explore.css'

const menuIcons = [
  {
    name: 'leaderbaord',
    disabled: false,
  },
  {
    name: 'token burn',
    disabled: false,
  },
  {
    name: 'economy',
    disabled: false,
  },
  {
    name: 'search',
    disabled: false,
  },
]

const Explore = () => {
  const [musicPref, setMusicPref] = useState(false)
  const [sfxPref, setSfxPref] = useState(false)

  const [alert, setAlert] = useState({ show: false, value: null })
  const [gameModal, setGameModal] = useState({ show: false, value: 'menu' })
  const [welcomeSplash, setWelcomeSplash] = useState(true)
  const [isDev, setIsDev] = useState(false)

  const apiRenLeaderboard = `https://api.ethernalelves.com/api/stats/renleaderboard`
  const apiMoonLeaderboard = `https://api.ethernalelves.com/api/stats/moonleaderboard`
  const apiEconomy = `https://api.ethernalelves.com/api/stats/economy`
  const apiBossLeaderbaord = `https://api.ethernalelves.com/api/stats/bosskillsleaderboard`
  const apiDailyRen = `https://api.ethernalelves.com/api/stats/daily/ren`
  const apiWeeklyRen = `https://api.ethernalelves.com/api/stats/weekly/ren`
  const apiDailyMoon = `https://api.ethernalelves.com/api/stats/daily/moon`
  const apiWeeklyMoon = `https://api.ethernalelves.com/api/stats/weekly/moon`
  const apiSacrificeLeaderboard = `https://api.ethernalelves.com/api/stats/sacrificeleaderboard`

  const [renLB, setRenLB] = useState([{ objectId: 'Fetching burn data...' }])
  const [moonLB, setMoonLB] = useState([{ objectId: 'Fetching burn data...' }])
  const [bossLB, setBossLB] = useState([{ objectId: 'Fetching boss kills...' }])
  const [dailyRen, setDailyRen] = useState([{ objectId: 'Fetching burn data...' }])
  const [dailyMoon, setDailyMoon] = useState([{ objectId: 'Fetching burn data...' }])
  const [weeklyRen, setWeeklyRen] = useState([{ objectId: 'Fetching burn data...' }])
  const [weeklyMoon, setWeeklyMoon] = useState([{ objectId: 'Fetching burn data...' }])
  const [changeBurn, setChangeBurn] = useState(true)
  const [renBurnData, setRenBurnData] = useState({})
  const [sacLb, setSacLb] = useState([])
  const [funnelData, setFunnelData] = useState([
    {
      id: 'Loading...',
      value: 0,
      label: 'Loading...',
    },
  ])
  const [changeChart, setChangeChart] = useState(true)

  const fetchRenLeaderboard = useCallback(async () => {
    const responseRen = await fetch(apiRenLeaderboard)
    const resultRen = await responseRen.json()
    setRenLB(resultRen)
  }, [apiRenLeaderboard])

  const fetchMoonLeaderboard = useCallback(async () => {
    const responseMoon = await fetch(apiMoonLeaderboard)
    const resultMoon = await responseMoon.json()
    setMoonLB(resultMoon)
  }, [apiMoonLeaderboard])

  const fetchRenEconomy = useCallback(async () => {
    const createFunnelData = []
    const responseRen = await fetch(apiEconomy)
    const resultRen = await responseRen.json()
    const renData = resultRen.map((ren) => {
      return { name: ren.objectId, burn: Math.floor(ren.burn) }
    })
    renData.sort((a, b) => {
      return a.burn - b.burn
    })
    const total = renData[renData.length - 1].burn
    const burned = renData[0].burn * -1
    const remaining = total - burned
    createFunnelData.push({
      id: `step_Total`,
      value: total,
      label: 'Total',
    })
    createFunnelData.push({
      id: `step_Burned`,
      value: burned,
      label: 'Burned',
    })
    createFunnelData.push({
      id: `step_Remaining`,
      value: remaining,
      label: 'Remaining',
    })
    setRenBurnData(renData)
    setFunnelData(createFunnelData)
  }, [apiEconomy])

  const fetchBossLeaderboard = useCallback(async () => {
    const responseBoss = await fetch(apiBossLeaderbaord)
    const resultBoss = await responseBoss.json()
    setBossLB(resultBoss)
  }, [apiBossLeaderbaord])

  const fetchDailyBurn = useCallback(async () => {
    const responseDailyRen = await fetch(apiDailyRen)
    const resultDailyRen = await responseDailyRen.json()
    const responseDailyMoon = await fetch(apiDailyMoon)
    const resultDailyMoon = await responseDailyMoon.json()
    setDailyRen(resultDailyRen)
    setDailyMoon(resultDailyMoon)
  }, [apiDailyRen, apiDailyMoon])

  const fetchWeeklyBurn = useCallback(async () => {
    const responseWeeklyRen = await fetch(apiWeeklyRen)
    const resultWeeklyRen = await responseWeeklyRen.json()
    const responseWeeklyMoon = await fetch(apiWeeklyMoon)
    const resultWeeklyMoon = await responseWeeklyMoon.json()
    setWeeklyRen(resultWeeklyRen)
    setWeeklyMoon(resultWeeklyMoon)
  }, [apiWeeklyRen, apiWeeklyMoon])

  const fetchSacrificeLeaderboard = useCallback(async () => {
    const responseSac = await fetch(apiSacrificeLeaderboard)
    const resultSac = await responseSac.json()
    const perChunk = 5
    const orderedSac = resultSac?.reduce((arr, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!arr[chunkIndex]) {
        arr[chunkIndex] = [] // start a new chunk
      }

      arr[chunkIndex].push(item)

      return arr
    }, [])
    setSacLb(orderedSac)
  }, [apiSacrificeLeaderboard])

  const handleChangeBurn = () => {
    setChangeBurn(!changeBurn)
  }

  const handleChangeChart = () => {
    setChangeChart(!changeChart)
  }

  useEffect(() => {
    fetchRenLeaderboard()
    fetchMoonLeaderboard()
    fetchRenEconomy()
    fetchBossLeaderboard()
    fetchDailyBurn()
    fetchWeeklyBurn()
    fetchSacrificeLeaderboard()
  }, [
    fetchRenLeaderboard,
    fetchMoonLeaderboard,
    fetchRenEconomy,
    fetchBossLeaderboard,
    fetchDailyBurn,
    fetchWeeklyBurn,
    fetchSacrificeLeaderboard,
  ])

  const [play, exposedData] = useSound(audioSprite, {
    volume: 0.1,
    soundEnabled: sfxPref,
    interrupt: true,
    sprite: {
      menu: [0, 400],
      popup: [456, 1775],
      bridge: [1775, 290],
      heal: [968, 200],
      attack: [968, 200],
    },
  })

  const [playTitle, exposedTitle] = useSound(titleMusic, {
    volume: 0.1,
    soundEnabled: musicPref,
    loop: true,
  })

  ///{ data, error, isLoading, fetch }

  const handleWelcomeSplash = () => {
    setWelcomeSplash(false)
    playTitle()
  }

  const handleClickBack = () => {
    setGameModal({ show: true, value: 'menu' })
    play({ id: 'menu' })
  }

  const togglePlaySfx = async () => {
    if (sfxPref == true) {
      setSfxPref(false)
    } else {
      setSfxPref(true)
    }
  }

  const togglePlayMusic = async () => {
    if (musicPref == true) {
      setMusicPref(false)
      exposedTitle.stop()
    } else {
      setMusicPref(true)
      playTitle({
        forceSoundEnabled: true,
        volume: 0.1,
        loop: true,
      })
    }
  }

  return (
    <>
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-col uppercase max-w-[calc(100vw_-_50px)] items-center justify-center" id="interface">
          {/* Main Menu Icons */}
          {gameModal.value === 'menu' ? (
            <div className="flex flex-col items-center fade-in">
              {!welcomeSplash ? (
                <>
                  <div
                    className="
                  flex flex-col 
                  justify-center items-center                
                  md:space-y-4               
                  space-y-1
                  w-full
                  h-full                                
                  md:max-h-[600px]
                  "
                  >
                    {menuIcons.map((icon) => {
                      if (!icon.disabled || isDev) {
                        return (
                          <button
                            key={icon.name}
                            onMouseEnter={() => {
                              play({ id: 'menu' })
                            }}
                            /*onMouseLeave={() => {
                            stop()
                          }}*/
                            className="uppercase btn-menu text-2xl md:text-3xl lg:text-5xl w-60 h-16 font-bold font-app p-5 md:w-64 lg:w-96 md:h-16 lg:h-20"
                            onClick={() => setGameModal({ value: icon.name })}
                          >
                            {icon.name}
                          </button>
                        )
                      }
                    })}
                  </div>
                  <button onClick={togglePlayMusic}>{musicPref ? 'Mute Music' : 'Play Music'}</button>
                  <button onClick={togglePlaySfx}>{sfxPref ? 'Mute SFX' : 'Play SFX'}</button>
                </>
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <h1 className="title-font md:text-7xl text-5xl text-transparent font-app border-text text-center pb-3 hidden md:flex">
                    ETHERNAL ELVES
                  </h1>

                  <button
                    className="uppercase btn-menu text-2xl md:text-4xl w-60 h-16 font-bold font-app md:w-72 md:h-16"
                    onClick={handleWelcomeSplash}
                  >
                    Explore
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="fade-in flex flex-col">
              {gameModal.value === 'leaderbaord' && (
                <WeeklyLeaderboard
                  renLB={renLB}
                  moonLB={moonLB}
                  bossLB={bossLB}
                  handleClickBack={handleClickBack}
                  playersArr={sacLb}
                />
              )}

              {gameModal.value === 'token burn' && (
                <Burn
                  dailyRen={dailyRen}
                  dailyMoon={dailyMoon}
                  weeklyRen={weeklyRen}
                  weeklyMoon={weeklyMoon}
                  changeBurn={changeBurn}
                  handleChangeBurn={handleChangeBurn}
                  handleClickBack={handleClickBack}
                />
              )}
              {gameModal.value === 'economy' && (
                <RenEconomyCharts
                  renBurnData={renBurnData}
                  funnelData={funnelData}
                  handleChangeChart={handleChangeChart}
                  changeChart={changeChart}
                  handleClickBack={handleClickBack}
                />
              )}

              {gameModal.value === 'search' && <ExploreWallet handleClickBack={handleClickBack} />}
            </div>
          )}
        </div>
        <AlertBar alert={alert} setAlert={setAlert} />
      </div>
    </>
  )
}

export default Explore
