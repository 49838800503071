const MidButton = ({ buttonText, onClick, loading, active }) => {
  const buttonBg = active ? 'bg-Button_A_Mid_active' : 'bg-Button_A_Mid_Button'

  return (
    <div className="flex mt-1 w-40 h-20 justify-center">
      <button
        className={`${buttonBg} hover:bg-Button_A_Mid_aimed bg-[length:100%_100%] font-app uppercase text-lg px-5 py-2 w-full`}
        onClick={onClick}
        disabled={loading}
      >
        {loading ? 'working...' : buttonText}
      </button>
    </div>
  )
}

export default MidButton
