import { layout, layout_flat, hex_to_pixel } from './helpers'

export const sortMapData = (mapData, hexagonSize) => {
  mapData.sort((a, b) => {
    return a.index - b.index
  })

  let fills = []
  mapData.forEach((hex) => {
    if (hex.terrain) {
      hex.terrain.map((layer) => {
        fills.push(layer.tile)
      })
    }
  })

  const uniqueFills = [...new Set(fills)]

  const startPos = hex_to_pixel(layout(layout_flat, hexagonSize, { x: 0, y: 0 }, 1), mapData[0])

  return { mapData: mapData, uniqueFills: uniqueFills, startPos: startPos }
}

export const placementRules = (tileType, layer, terrain, setAlert) => {
  let isWater = tileType.includes('water') ? true : false
  let isRiver = tileType.includes('river') ? true : false
  let isCoast = tileType.includes('coast') ? true : false
  let isStructure = layer == 2 ? true : false
  let isRoad = tileType.includes('road') ? true : false
  let isMountain = tileType.includes('mountain') || tileType.includes('hill') ? true : false
  let isDock = tileType.includes('dock') ? true : false

  let existingWater = terrain.find((t) => t.type.includes('water')) ? true : false
  let existingCoast = terrain.find((t) => t.type.includes('coast')) ? true : false
  let existingStructure = terrain.find((t) => t.layer == 2) ? true : false
  let existingRoad = terrain.find((t) => t.type.includes('road')) ? true : false
  let existingMountain = terrain.find((t) => t.type.includes('mountain') || t.type.includes('hill')) ? true : false
  let existingRiver = terrain.find((t) => t.type.includes('river')) ? true : false
  let existingDock = terrain.find((t) => t.type.includes('dock')) ? true : false

  if (isWater && existingStructure) {
    setAlert({ show: true, value: { content: 'Cannot place water on a structure' } })
    return
  }

  if (isWater && existingRoad) {
    setAlert({ show: true, value: { content: 'Cannot place water on a road' } })
    return
  }

  if (isWater && existingMountain) {
    setAlert({ show: true, value: { content: 'Cannot place water on a mountain' } })
    return
  }

  if (isStructure && existingWater && !isDock) {
    setAlert({ show: true, value: { content: 'Cannot place a structure on water' } })
    return
  }

  if (isStructure && existingRoad) {
    setAlert({ show: true, value: { content: 'Cannot place a structure on a road' } })
    return
  }

  if (isStructure && existingMountain) {
    setAlert({ show: true, value: { content: 'Cannot place a structure on a mountain' } })
    return
  }

  if (isRoad && existingWater) {
    setAlert({ show: true, value: { content: 'Cannot place a road on water' } })
    return
  }

  if (isRoad && existingStructure) {
    setAlert({ show: true, value: { content: 'Cannot place a road on a structure' } })
    return
  }

  if (isRoad && existingMountain) {
    setAlert({ show: true, value: { content: 'Cannot place a road on a mountain' } })
    return
  }

  if (isMountain && existingWater) {
    setAlert({ show: true, value: { content: 'Cannot place a mountain on water' } })
    return
  }

  if (isMountain && existingStructure) {
    setAlert({ show: true, value: { content: 'Cannot place a mountain on a structure' } })
    return
  }

  if (isMountain && existingRoad) {
    setAlert({ show: true, value: { content: 'Cannot place a mountain on a road' } })
    return
  }

  if (isCoast && existingWater) {
    setAlert({ show: true, value: { content: 'Cannot place a coast on water' } })
    return
  }

  if (isCoast && existingStructure && !existingDock) {
    console.log(existingDock)
    setAlert({ show: true, value: { content: 'Cannot place a coast on a structure' } })
    return
  }

  if (isCoast && existingRoad) {
    setAlert({ show: true, value: { content: 'Cannot place a coast on a road' } })
    return
  }

  if (isCoast && existingMountain) {
    setAlert({ show: true, value: { content: 'Cannot place a coast on a mountain' } })
    return
  }

  /* if (isDock && existingWater) {
    setAlert({ show: true, value: { content: 'Cannot place a dock on water' } })
    return
  }*/

  if (isDock && !existingCoast) {
    setAlert({ show: true, value: { content: 'Can only place a dock on coast' } })
    return
  }

  if (isRoad && existingRiver) {
    setAlert({ show: true, value: { content: 'Cannot place a road on a river' } })
    return
  }

  if (isRiver && existingRoad) {
    setAlert({ show: true, value: { content: 'Cannot place a river on a road' } })
    return
  }

  if (isRiver && existingStructure) {
    setAlert({ show: true, value: { content: 'Cannot place a river on a structure' } })
    return
  }

  if (isRiver && existingMountain) {
    setAlert({ show: true, value: { content: 'Cannot place a river on a mountain' } })
    return
  }

  if (isRiver && existingWater) {
    setAlert({ show: true, value: { content: 'Cannot place a river on water' } })
    return
  }

  if (isWater && existingRiver) {
    setAlert({ show: true, value: { content: 'Cannot place water on a river' } })
    return
  }

  if (isWater && existingCoast) {
    setAlert({ show: true, value: { content: 'Cannot place water on a coast' } })
    return
  }

  if (isWater && existingMountain) {
    setAlert({ show: true, value: { content: 'Cannot place water on a mountain' } })
    return
  }

  if (isWater && existingStructure) {
    setAlert({ show: true, value: { content: 'Cannot place water on a structure' } })
    return
  }

  if (isWater && existingRoad) {
    setAlert({ show: true, value: { content: 'Cannot place water on a road' } })
    return
  }

  if (isWater && existingDock) {
    setAlert({ show: true, value: { content: 'Cannot place water on a dock' } })
    return
  }

  if (isWater && existingRiver) {
    setAlert({ show: true, value: { content: 'Cannot place water on a river' } })
    return
  }

  if (isMountain && existingRiver) {
    setAlert({ show: true, value: { content: 'Cannot place a mountain on a river' } })
    return
  }

  if (isStructure && existingRiver) {
    setAlert({ show: true, value: { content: 'Cannot place a structure on a river' } })
    return
  }

  if (isRoad && existingRiver) {
    setAlert({ show: true, value: { content: 'Cannot place a road on a river' } })
    return
  }

  return true

  //END RULES
}
