import React from 'react'
import MidButton from '../MidButton'

const PaperPopup = ({ show, setShow, children, background }) => {
  if (!show) return <></>

  return (
    <div className="fixed w-full h-full top-0 left-0 z-50">
      <div
        className=" md:bg-paper-popup
                    fade-in       
                    content-around      
                    mx-auto md:mx-auto md:my-16 my-0
                    md:aspect-square px-6 py-14
                    md:max-w-screen-sm md:max-h-screen-sm
                    h-screen
                    flex flex-col items-center 
                    bg-no-repeat bg-cover md:bg-contain
                    bg-gradient-to-b from-stone-800 via-stone-700  to-stone-600                    
                    "
      >
        <div className="flex flex-col items-end w-full">
          <div
            onClick={() => setShow(false)}
            className="hover:bg-red-800 bg-red-800/60 w-8 h-8 rounded-full text-center flex items-center justify-center cursor-pointer"
          >
            <span>X</span>
          </div>
        </div>

        <div className="mt-1 px-4 overflow-hidden flex flex-col items-center justify-center">{children}</div>
      </div>
    </div>
  )
}

export default PaperPopup
