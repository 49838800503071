import * as THREE from 'three'
import { useMemo } from 'react'

function BeveledHexagonGeometry({ size = 1, depth, ...rest }) {
  const shape = useMemo(() => {
    const shape = new THREE.Shape()
    const sides = 6,
      Xcenter = 0,
      Ycenter = 0

    shape.moveTo(Xcenter + size * Math.cos(0), Ycenter + size * Math.sin(0))

    for (let i = 1; i <= sides; i += 1) {
      shape.lineTo(
        Xcenter + size * Math.cos((i * 2 * Math.PI) / sides),
        Ycenter + size * Math.sin((i * 2 * Math.PI) / sides),
      )
    }
    return shape
  }, [size])

  const settings = useMemo(
    () => ({
      steps: 1,
      depth: depth,
      bevelEnabled: true,
      bevelThickness: 0.1,
      bevelSize: 0.1,
      bevelOffset: 0,
      bevelSegments: 8,
    }),
    [],
  )

  return <extrudeGeometry args={[shape, settings]} {...rest} />
}

export default BeveledHexagonGeometry
