import React from 'react'

import REN from '../../../assets/images/ren.png'
import MOON from '../../../assets/images/moon.png'
import Artifacts from '../../../assets/images/artifacts.png'
import Scrolls from '../../../assets/images/scrolls.png'
import Aether from '../../../assets/images/resources/Aether.png'
import Iron from '../../../assets/images/resources/Iron.png'
import Terra from '../../../assets/images/resources/Terra.png'
import Frost from '../../../assets/images/resources/Frost.png'
import Magma from '../../../assets/images/resources/Magma.png'
import ZUG from '../../../assets/images/tokens/zug.png'

const Loot = ({ lootData }) => {
  const lootItems = (n, img) => {
    return (
      <div className="flexloot">
        <img src={img} width={img === ZUG ? 50 : 40} height={40} alt={lootData[n].name} className="lootborder" />
        <div className="grid">
          <div>{lootData[n].value}</div>
          <div>{lootData[n].name}</div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="loot">
        <div>
          <div className="flex justify-center">{lootData[0].name}</div>
          <div className="lootItems">
            {lootItems(1, REN)}
            {lootItems(2, MOON)}
            {lootItems(3, ZUG)}
            {lootItems(4, MOON)}
          </div>
        </div>
        <div>
          <div className="flex justify-center">{lootData[5].name}</div>
          <div className="lootItems">
            {lootItems(6, Artifacts)}
            {lootItems(7, Scrolls)}
            {lootItems(8, Aether)}
            {lootItems(9, Iron)}
            {lootItems(10, Terra)}
            {lootItems(11, Frost)}
            {lootItems(12, Magma)}
          </div>
        </div>
      </div>
    </>
  )
}

export default Loot
