import React from 'react'

const liCls = 'p-3 border text-gray-700 hover:text-white hover:bg-indigo-700 cursor-pointer'

const DropDownCardNoCount = ({ data = [], onChange, selected }) => {
  const handleClick = (option) => {
    onChange(option)
  }

  return (
    <div className="shadow h-auto w-56 absolute">
      <ul className="mul-dropdown-content">
        {data.map((item, i) => (
          <li key={i} className={selected.includes(item) ? 'active' : null} onClick={() => handleClick(item)}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DropDownCardNoCount
