import React from 'react'
import DailyBurn from './Components/DailyBurn'
import WeeklyBurn from './Components/WeeklyBurn'

function Burn({ dailyRen, dailyMoon, weeklyRen, weeklyMoon, changeBurn, handleChangeBurn, handleClickBack }) {
  return (
    <>
      <h2 className="text-xl text-center font-bold flex justify-center md:text-2xl lg:text-3xl bg-no-repeat bg-[length:100%_100%] w-[70%] md:w-1/2 lg:w-[38%] bg-curlyButton mb-2">
        {changeBurn ? 'Daily' : 'Weekly'} Burn{' '}
      </h2>
      <div className="leaderboard flex-wrap">
        {changeBurn ? (
          <DailyBurn dailyRen={dailyRen} dailyMoon={dailyMoon} handleClickBack={handleClickBack} />
        ) : (
          <WeeklyBurn weeklyRen={weeklyRen} weeklyMoon={weeklyMoon} handleClickBack={handleClickBack} />
        )}
      </div>
      <div className="flex flex-col items-center gap-2">
        <button className="btn-menu w-[120px] h-[50px] text-xl md:text-2xl" onClick={handleChangeBurn}>
          {!changeBurn ? 'Daily' : 'Weekly'}
        </button>
        <button className="btn--back btn-menu text-xl mr-6 md:mr-0 md:text-2xl" onClick={handleClickBack}>
          Back
        </button>
      </div>
    </>
  )
}

export default Burn
