import React, { useState } from 'react'

import { useMoralisQuery, useMoralisCloudFunction } from 'react-moralis'
import Dropdown from '../../components/Dropdown'

import ToggleMenu from './ToggleMenu'
import MinMaxMenu from './MinMaxMenu'
import InputMenu from './InputMenu'

const races = ['Primeborne', 'Woodborne', 'Lightborne', 'Darkborne']
const classes = ['Druid', 'Ranger', 'Assassin']
const chainList = ['eth', 'polygon']
const accessories = [
  "Azrael's Crest",
  'Bear',
  'Claws',
  'Crown of Dahagon ',
  'Drus Ruler of The Oaks',
  'El Machina',
  'Eriel Angel of Nature',
  'Euriel The Protector',
  'Liger',
  "Mechadon's Vizard",
  'Necklace',
  'Necklace & Armband',
  'None',
  'Wristband',
  'Wristband & Necklace',
]

const SentinelQueryPanel = ({ setData, setIsDataLoading }) => {
  const [showMore, setShowMore] = useState(false)
  const [address, setAddress] = useState('')
  const [raceState, setRaceState] = useState(new Array(races.length).fill(true))
  const [classState, setClassState] = useState(new Array(classes.length).fill(true))
  const [accessoriesState, setAccessoriesState] = useState(new Array(accessories.length).fill(true))
  const [minMaxLevel, setMinMaxLevel] = useState({
    min: 0,
    max: 100,
  })
  const [minMaxWeapon, setMinMaxWeapon] = useState({
    min: 0,
    max: 5,
  })

  const handleFetch = async () => {
    setIsDataLoading(true)
    fetch()
      .then((data) => {
        setData(data)
        setIsDataLoading(false)
      })
      .catch((error) => console.error(error))
  }

  const { fetch } = useMoralisQuery(
    'GameplaySentinels',
    (query) =>
      query
        .greaterThanOrEqualTo('elf_weaponTier', parseInt(minMaxWeapon.min))
        .lessThanOrEqualTo('elf_weaponTier', parseInt(minMaxWeapon.max))
        .greaterThanOrEqualTo('elf_level', parseInt(minMaxLevel.min))
        .lessThanOrEqualTo('elf_level', parseInt(minMaxLevel.max))
        .containedIn(
          'elf_class',
          classes.filter((r, i) => classState[i]),
        )
        .containedIn(
          'elf_race',
          races.filter((r, i) => raceState[i]),
        )
        .containedIn(
          'elf_accessories',
          accessories.filter((r, i) => accessoriesState[i]),
        )
        .startsWith('owner_of', address.toLowerCase())
        .containedIn('chain', chainList)
        .limit(6666)
        .skip(0),
    [
      minMaxWeapon,
      minMaxLevel,
      minMaxLevel,
      accessories,
      accessoriesState,
      races,
      raceState,
      classes,
      classState,
      chainList,
      address,
    ],
    { autoFetch: false },
  )

  return (
    <>
      <InputMenu title="Owner" valueTitle="Address" inputValue={address} setInputValue={setAddress} />
      <ToggleMenu title="Class" names={classes} toggles={classState} setToggles={setClassState} />
      <MinMaxMenu title="Level" min={0} max={100} minMax={minMaxLevel} setMinMax={setMinMaxLevel} />
      <MinMaxMenu title="Weapon Tier" min={0} max={5} minMax={minMaxWeapon} setMinMax={setMinMaxWeapon} />
      {showMore === false ? (
        <a
          href="#"
          className="explore-show"
          onClick={() => {
            setShowMore(true)
          }}
        >
          More options...
        </a>
      ) : (
        <>
          <ToggleMenu title="Race" names={races} toggles={raceState} setToggles={setRaceState} />
          <ToggleMenu
            title="Accessories"
            names={accessories}
            toggles={accessoriesState}
            setToggles={setAccessoriesState}
          />
        </>
      )}

      <button className="btn bg-longButtonA btn-explore" onClick={handleFetch}>
        Search...
      </button>
    </>
  )
}

export default SentinelQueryPanel
