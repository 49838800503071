import { React } from 'react'
import TreeChart from './Components/TreeChart'
import FunnelChart from './Components/FunnelChart'
import Loader from '../../components/Loader'

function RenEconomyCharts({ renBurnData, funnelData, handleChangeChart, changeChart, handleClickBack }) {
  if (funnelData[0].value === 0) {
    return <Loader text={'Loading charts...'} />
  } else
    return (
      <>
        <h2 className="prices text-xl md:text-4xl">Ren Economy</h2>
        <div className="flex md:justify-center gap-3">
          <button
            className="btn-menu w-1/2 md:w-1/6 text-xl md:text-2xl"
            onClick={handleChangeChart}
            disabled={changeChart}
          >
            Funnel chart
          </button>
          <button
            className="btn-menu w-1/2 md:w-1/6 text-xl md:text-2xl"
            onClick={handleChangeChart}
            disabled={!changeChart}
          >
            Tree chart
          </button>
          <button className="btn--back btn-menu mt-1 md:mt-0 text-xl md:text-2xl" onClick={handleClickBack}>
            Back
          </button>
        </div>
        <div className="chartLG">
          {!changeChart ? (
            <TreeChart
              width={1200}
              height={800}
              renBurnData={renBurnData}
              margin={{ top: 50, left: 45, right: 250, bottom: 50 }}
            />
          ) : (
            <div className="funnelchart">
              <FunnelChart data={funnelData} />
            </div>
          )}
        </div>
        <div className="chartSM ">
          {!changeChart ? (
            <TreeChart
              width={380}
              height={900}
              renBurnData={renBurnData}
              margin={{ top: 10, left: 45, right: 110, bottom: 10 }}
            />
          ) : (
            <div className="funnelchart">
              <FunnelChart data={funnelData} />
            </div>
          )}
        </div>
      </>
    )
}

export default RenEconomyCharts
