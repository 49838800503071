import React, { useState } from 'react'

import { DropdownSelector } from '../../components/Dropdown'
import ElfCard from './ElfCard'

const SentinelDisplayPanel = ({ data }) => {
  const [order, setOrder] = useState('Ascending')
  const [orderBy, setOrderBy] = useState('Token ID')

  const orderButtons = () => {
    return (
      <div className="order-buttons">
        <DropdownSelector
          title="Order By"
          options={['Token ID', 'Level', 'Weapon Level']}
          onChange={setOrderBy}
          selected={orderBy}
        />
        <DropdownSelector title="Order" options={['Ascending', 'Descending']} onChange={setOrder} selected={order} />
      </div>
    )
  }

  const sortData = (a, b) => {
    const compareFn =
      order === 'Ascending'
        ? function (a, b) {
            return a < b
          }
        : function (a, b) {
            return a > b
          }

    switch (orderBy) {
      case 'Token ID':
        return compareFn(a.get('token_id'), b.get('token_id')) ? -1 : 1
      case 'Level':
        return compareFn(a.get('elf_level'), b.get('elf_level')) ? -1 : 1
      case 'Weapon Level':
        return compareFn(a.get('elf_weaponTier'), b.get('elf_weaponTier')) ? -1 : 1
    }
  }

  return (
    <>
      <div className="top-right">
        <div className="result-header">
          <h1>Results: {data.length}</h1>
          {orderButtons()}
        </div>
      </div>
      <div id="right-wrap" className="panel-wrap">
        <div
          className="
        flex flex-wrap gap-2 mb-auto p-3        
        "
        >
          {data &&
            [...data].sort(sortData).map((line) => {
              return <ElfCard key={line.get('token_id')} data={line} />
            })}
        </div>
      </div>
    </>
  )
}

export default SentinelDisplayPanel
