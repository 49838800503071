import { React } from 'react'

const DailyBurn = ({ dailyRen, dailyMoon }) => {
  if (!dailyRen) {
    return <div>Fetching data...</div>
  } else {
    const totalRenBurn = new Intl.NumberFormat('en-US').format(Math.floor(dailyRen[dailyRen.length - 1]?.burn))
    const totalMoonBurn = new Intl.NumberFormat('en-US').format(Math.floor(dailyMoon[dailyMoon.length - 1]?.burn))
    return (
      <>
        <div className="LBcontainer bg-paper bg-[length:100%_100%] max-w-xl">
          <div className="top5">Ren burn</div>
          {dailyRen.map((lb, key) => {
            const burn = Math.floor(Number(lb.burn))
            const result = new Intl.NumberFormat('en-US').format(burn)

            if (lb.objectId === 'Total') {
              return
            } else {
              return (
                <div key={key} className="LBtext">
                  {!lb.burn ? (
                    <div>{`${lb.objectId}`}</div>
                  ) : (
                    <div>
                      {`${lb.objectId}: `}{' '}
                      <span className={`${burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
                        burn < 0 ? '' : '+'
                      }${result} ren `}</span>
                    </div>
                  )}
                </div>
              )
            }
          })}
          <div className="font-bold">
            {`Total REN burned this week: `}
            <span className={`${dailyRen[dailyRen.length - 1]?.burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
              dailyRen[dailyRen.length - 1]?.burn > 0 ? '+' : ''
            }${!dailyRen[0].burn ? 0 : totalRenBurn}`}</span>
          </div>
        </div>
        <div className="LBcontainer bg-paper bg-[length:100%_100%] max-w-xl">
          <div className="top5">Moon burn</div>
          {dailyMoon.map((lb, key) => {
            const burn = Math.floor(Number(lb.burn))
            const result = new Intl.NumberFormat('en-US').format(burn)

            if (lb.objectId === 'Total') {
              return
            } else {
              return (
                <div key={key} className="LBtext">
                  {!lb.burn ? (
                    <div>{`${lb.objectId}`}</div>
                  ) : (
                    <div className="flex flex-wrap break-all">
                      <div>{`${lb.objectId}: `}</div>
                      <div className={`${burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
                        burn < 0 ? '' : '+'
                      }${result} moon `}</div>
                    </div>
                  )}
                </div>
              )
            }
          })}
          <div className="font-bold">
            {`Total MOON burned this week: `}
            <span className={`${dailyMoon[dailyMoon.length - 1]?.burn < 0 ? 'text-red-800' : 'text-green-400'}`}>{`${
              dailyMoon[dailyMoon.length - 1]?.burn > 0 ? '+' : ''
            }${!dailyMoon[0].burn ? 0 : totalMoonBurn}`}</span>
          </div>
        </div>
      </>
    )
  }
}

export default DailyBurn
