import React from 'react'
import ExploreSentinels from './ExploreSentinels'

import './style.css'

const ExploreOld = ({}) => {
  return (
    <>
      <ExploreSentinels />
    </>
  )
}

export default ExploreOld
