import React from 'react'
import prismBridge from '../../assets/images/prismBridge.gif'
import thevoid from '../../assets/images/thevoid.png'
import RenIcon from '../../assets/images/icons/ren.gif'
import loader from '../../assets/images/loader.gif'

const Loader = ({ text, action }) => {
  let loaderGraphic = (
    <div>
      {' '}
      <img src={loader} />{' '}
    </div>
  )

  if (action === 'transfers') {
    loaderGraphic = (
      <div>
        <img src={prismBridge} />
      </div>
    )
  }
  if (action === 'void') {
    loaderGraphic = (
      <div>
        <img src={thevoid} />
      </div>
    )
  }
  if (action === 'token') {
    loaderGraphic = (
      <div>
        <img src={RenIcon} />
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-center items-center m-[10%] text-base text-white uppercase">
      <div>{loaderGraphic}</div>
      <div className="mt-3 flex justify-center items-center text-center">
        <span className="font-bold">{text ? text : 'Loading... stuck? try the green button'}</span>
      </div>
    </div>
  )
}

export default Loader
