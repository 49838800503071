import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { MoralisProvider } from 'react-moralis'

const creds = {
  appId:
    process.env.REACT_APP_PROD_ENV === 'production'
      ? process.env.REACT_APP_MORALIS_APPID
      : process.env.REACT_APP_MORALIS_TESTNET_APPID,
  serverUrl:
    process.env.REACT_APP_PROD_ENV === 'production'
      ? process.env.REACT_APP_MORALIS_URL
      : process.env.REACT_APP_MORALIS_TESTNET_URL,
}

/*
<React.StrictMode>
    </React.StrictMode>
    */

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <MoralisProvider appId={creds.appId} serverUrl={creds.serverUrl}>
    <App />
  </MoralisProvider>,
)
