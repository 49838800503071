import { useState, useRef, useEffect } from 'react'
import menuImg from '../../assets/images/menu.png'
import { NavLink } from 'react-router-dom'
import { useMoralis, useMoralisQuery } from 'react-moralis'
import Moralis from 'moralis-v1'

import artifactsIcon from '../../assets/images/icons/Artifact_1.png'
import elvesIcon from '../../assets/images/icons/sentinels.png'
import eldersIcon from '../../assets/images/icons/1.png'
import settlementsIcon from '../../assets/images/icons/settlements.png'
import scrollsIcon from '../../assets/images/icons/crusade_scroll_glow.png'
import moon from '../../assets/images/icons/moon.gif'
import ren from '../../assets/images/icons/ren.gif'
import zug from '../../assets/images/tokens/zug.png'
import discordImg from '../../assets/images/discord.png'
import twitterImg from '../../assets/images/twitter.png'
import openseaImg from '../../assets/images/opensea.png'
import logoImg from '../../assets/images/logo.png'
import aetherImg from '../../assets/images/resources/Aether.png'
import frostImg from '../../assets/images/resources/Frost.png'
import terraImg from '../../assets/images/resources/Terra.png'
import ironImg from '../../assets/images/resources/Iron.png'
import magmaImg from '../../assets/images/resources/Magma.png'

//vaults
import common from '../../assets/images/vaults/common.png'
import rare from '../../assets/images/vaults/rare.png'
import ancient from '../../assets/images/vaults/ancient.png'
import legendary from '../../assets/images/vaults/legendary.png'
import divine from '../../assets/images/vaults/devine.png'

import './style.css'

const abbreviatedInt = (int) =>
  Intl.NumberFormat('en-US', {
    //notation: 'compact', people want the full balance
    maximumFractionDigits: 2,
  }).format(int)

const SideNav = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [nftData, setNftData] = useState()

  const drop = useRef('sidenav')
  const handleClick = (e) => {
    if (!drop.current) return
    if (!e.target.closest(`#${drop.current.id}`) && showMenu) {
      setShowMenu(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  //State variables
  const { isAuthenticated, user, refetchUserData, isUserUpdating } = useMoralis()

  const PlayerBalances = useMoralisQuery(
    'PlayerBalances',
    (query) => query.equalTo('ethAddress', isAuthenticated ? user.attributes.ethAddress : null),

    [isAuthenticated, showMenu],
    {
      live: false,
    },
  )

  const getNftCollectionData = async () => {
    const currentTime = new Date().getTime()
    const currentTimeStamp = Math.floor(currentTime / 1000)
    const nftCollectionData = await Moralis.Cloud.run('collectionSideNavState', { timestamp: currentTimeStamp })
    setNftData(nftCollectionData)
  }

  const toggleMenu = async () => {
    setShowMenu(!showMenu)
    await getNftCollectionData()
    refetchUserData()
  }

  const navItems = [
    {
      name: 'Home',
      path: '/',
      isAuthenticated: false,
    },
    {
      name: 'Play',
      path: '/play',
      isAuthenticated: false,
    },
    {
      name: 'Profile',
      path: '/profile',
      isAuthenticated: true,
    },
    /*{
      name: 'Transfers',
      path: '/transfers',
      isAuthenticated: true,
  },
  {
      name: 'Terms',
      path: '/terms',
      isAuthenticated: false,
    },
  */
    {
      name: 'Explore',
      path: '/explore',
      isAuthenticated: false,
    },
  ]

  const socialLinks = [
    {
      name: 'Twitter',
      path: 'https://twitter.com/EthernalElves',
      icon: twitterImg,
    },
    {
      name: 'Discord',
      path: 'https://discord.com/invite/ethernalelves',
      icon: discordImg,
    },
    {
      name: 'OpenSea',
      path: 'https://opensea.io/collection/ethernalelves',
      icon: openseaImg,
    },
  ]

  return (
    <div className="z-50" ref={drop} id="sidenav">
      <img onClick={toggleMenu} className="cursor-pointer z-[1] h-6" src={menuImg} alt="close" />

      {showMenu && (
        <div className="sidenav fade-in w-full lg:w-[600px] h-full fixed z-50 top-0 left-0 bg-black/90 overflow-hidden pt-14 text-center overflow-y-scroll">
          <>
            <button className="closebtn" onClick={toggleMenu}>
              &times;
            </button>
            <div className="flex flex-col items-center justify-center pb-6">
              <img src={logoImg} alt="logo" />
            </div>
            {navItems.map((item, index) => {
              return (
                <NavLink
                  key={item.name}
                  className="nav-item"
                  to={item.path}
                  activeClassName="text-white"
                  onClick={toggleMenu}
                >
                  {item.name}
                </NavLink>
              )
            })}

            <a
              className="nav-item"
              href="https://docs.ethernalelves.com/about/storyline"
              target="_blank"
              rel="noreferrer"
            >
              Docs
            </a>

            <div className="justify-center items-center lg:gap-3 gap-1 w-full p-4 hidden md:flex">
              {socialLinks.map((item, index) => {
                return (
                  <a key={item.name} href={item.path} className="w-[45px] h-[45px] rounded-full border-[#4f4945] p-1">
                    <img className="bg-yellow-50 rounded-full p-1" src={item.icon} alt={item.name} />
                  </a>
                )
              })}
            </div>

            {!PlayerBalances.isLoading && isAuthenticated && PlayerBalances.data && (
              <>
                <div className="flex flex-col p-2 text-left overflow-y-scroll md:overflow-auto text-xs md:text-base">
                  <hr />
                  <span className="p-3">
                    {' '}
                    hiya{' '}
                    {user.attributes.discordUser
                      ? user.attributes.discordUser
                      : user.attributes.ensName
                      ? user.attributes.ensName
                      : user.attributes.ethAddress}
                    !
                  </span>

                  <div className="px-4 flex flex-wrap">
                    {nftData?.totalElders && (
                      <div className="item-info p-3">
                        <img src={eldersIcon} alt="elves" />
                        <strong>{nftData.totalElders} Elders</strong>
                        <span>{nftData.freeElders} ready</span>
                      </div>
                    )}
                    {nftData?.totalSentinels && (
                      <div className="item-info p-3">
                        <img src={elvesIcon} alt="elves" />
                        <strong>{nftData.totalSentinels} Sentinels</strong>
                        <span>{nftData.freeSentinels} ready</span>
                      </div>
                    )}
                    {nftData?.totalSettlements && (
                      <div className="item-info p-3">
                        <img src={settlementsIcon} alt="elves" />
                        <strong>{nftData.totalSettlements} Settlements</strong>
                      </div>
                    )}
                  </div>
                  <div className="px-4 flex flex-wrap">
                    <div className="item-info p-3">
                      <img src={ren} alt="moon" />
                      <strong>{abbreviatedInt(PlayerBalances.data[0]?.attributes.ren)} $REN</strong>
                      <span>Game balance</span>
                    </div>

                    <div className="item-info p-3">
                      <img src={moon} alt="moon" />
                      <strong>{abbreviatedInt(PlayerBalances.data[0]?.attributes.moon)} $MOON</strong>
                      <span>Game balance</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={zug} alt="moon" />
                      <strong>{abbreviatedInt(PlayerBalances.data[0]?.attributes.zug || 0)} $ZUG</strong>
                      <span>Game balance</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={scrollsIcon} alt="scroll" />
                      <strong>{PlayerBalances.data[0]?.attributes.scrolls} Scrolls</strong>
                      <span>Available</span>
                    </div>

                    <div className="item-info p-3">
                      <img src={artifactsIcon} alt="artifact" />
                      <strong>{PlayerBalances.data[0]?.attributes.artifacts} Artifacts</strong>
                      <span>Available for mint</span>
                    </div>
                  </div>
                  <div className="font-bold pl-3 text-sm">Resources</div>
                  <div className="px-4 flex flex-wrap text-sm">
                    <div className="item-info p-3">
                      <img src={aetherImg} alt="aether" />
                      <strong>{PlayerBalances.data[0]?.attributes.aether} </strong>
                      <span>Aether</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={ironImg} alt="iron" />
                      <strong>{PlayerBalances.data[0]?.attributes.iron} </strong>
                      <span>Iron</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={terraImg} alt="terra" />
                      <strong>{PlayerBalances.data[0]?.attributes.terra} </strong>
                      <span>Terra</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={frostImg} alt="frost" />
                      <strong>{PlayerBalances.data[0]?.attributes.frost} </strong>
                      <span>Frost</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={magmaImg} alt="magma" />
                      <strong>{PlayerBalances.data[0]?.attributes.magma || 0} </strong>
                      <span>Magma</span>
                    </div>
                  </div>
                  <div className="font-bold pl-3 text-sm">Vaults</div>
                  <div className="px-4 flex flex-wrap text-sm capitalize">
                    <div className="item-info p-3">
                      <img src={common} alt="common" />
                      <strong>{PlayerBalances.data[0]?.attributes.common || 0} </strong>
                      <span>Cmn</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={rare} alt="rare" />
                      <strong>{PlayerBalances.data[0]?.attributes.rare || 0} </strong>
                      <span>rare</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={legendary} alt="legendary" />
                      <strong>{PlayerBalances.data[0]?.attributes.legendary || 0} </strong>
                      <span>legendary</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={ancient} alt="ancient" />
                      <strong>{PlayerBalances.data[0]?.attributes.ancient || 0} </strong>
                      <span>ancient</span>
                    </div>
                    <div className="item-info p-3">
                      <img src={divine} alt="divine" />
                      <strong>{PlayerBalances.data[0]?.attributes.divine || 0} </strong>
                      <span>divine</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      )}
    </div>
  )
}
export default SideNav
