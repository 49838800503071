import {
  Hex,
  layout,
  layout_pointy,
  layout_flat,
  hex_to_pixel,
  hexToPixel,
  polygon_corners,
  hashHexFunction,
  hex_direction,
  hex_neighbor,
  hex_round,
  hex_distance,
  calculateCoordinates,
} from '../helpers'
import { sortMapData } from '../utils'

import { useState, useEffect } from 'react'
import Moralis from 'moralis-v1'

const RectMap = ({ height, width, viewBox, mapImageData, handleHexClick, map, hexagonSize, mapMode, id }) => {
  const [neighbors, setNeighbors] = useState([])
  const [patterns, setPatterns] = useState([])
  const [mapData, setMapData] = useState([])
  const [origin, setOrigin] = useState({ x: 0, y: 0 })
  const [loading, setLoading] = useState(true)
  const [plotData, setPlotData] = useState(null)

  const [activeTile, setActiveTile] = useState(null)

  const getMapFromMoralis = async () => {
    setLoading(true)
    const cacheBuster = Math.floor(Math.random() * 1000000000)
    const maps = await fetch(`https://api.ethernalelves.com/api/world/?cb=${cacheBuster}`)
      .then((response) => response.json())
      .then((data) => data)
    //loop through maps.mapArray and create a distinct list of owners and count the frequency of each owner

    const owners = maps.map((item) => item.owner)
    const uniqueOwners = [...new Set(owners)]
    const ownerCounts = uniqueOwners.map((owner) => {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16)
      return {
        owner: owner,
        count: owners.filter((x) => x === owner).length,
        color: '#' + randomColor,
      }
    })
    //sort the ownerCounts array by count
    const sortedOwnerCounts = ownerCounts.sort((a, b) => b.count - a.count)

    console.log('sortedOwnerCounts', sortedOwnerCounts)

    //loop through the mapArray and assign each hex a color based on the ownerCounts array
    const mapArray = maps.map((item) => {
      const owner = sortedOwnerCounts.find((x) => x.owner === item.owner)
      return {
        ...item,
        color: owner.color,
      }
    })
    setMapData(mapArray)
    setLoading(false)
  }

  useEffect(() => {
    getMapFromMoralis()
  }, [mapImageData, map])

  const polygon_corners = (x1, y1, height, width, scaler) => {
    const x2 = x1 + width * scaler
    const y2 = y1 + height * scaler
    return [
      { x: x1, y: y1 },
      { x: x2, y: y1 },
      { x: x2, y: y2 },
      { x: x1, y: y2 },
    ]
  }

  const handlePlotClick = async (plot) => {
    setLoading(true)
    const maps = await fetch(`https://api.ethernalelves.com/api/settlements/${plot.tokenId}`)
      .then((response) => response.json())
      .then((data) => data)
    setPlotData({ ...plot, ...maps })
    console.log('plotData', plotData)
    setLoading(false)
  }

  const PlotDetails = ({ plot }) => {
    return (
      <div className="flex flex-col">
        <span className="text-sm">Settlement: {plot.tokenId}</span>
        <span className="text-sm">Owner: {plot.owner}</span>
        <span className="text-sm">Name: {plot.name}</span>
        <img src={plot.image} className="h-48 w-48" />
      </div>
    )
  }

  return (
    <>
      <div>{plotData && <PlotDetails plot={plotData} />}</div>

      {mapData && (
        <svg width={'100%'} height={360} viewBox="-5 0 3200 720" id={id}>
          <g>
            {mapData.map((hex) => {
              const x = hex.x
              const y = -hex.y
              const height = 18
              const width = 32
              const scaler = 1.99

              const corners = polygon_corners(x, y, height, width, scaler)
              const points = corners.map((corner) => `${corner.x},${corner.y}`).join(' ')

              let fill = hex.color

              return (
                <g transform={`translate(${x}, ${y})`} key={hex.tokenId}>
                  <polygon
                    points={points}
                    fill={fill}
                    onClick={() => handlePlotClick(hex)}
                    disabled={loading}
                    className={loading ? 'cursor-not-allowed' : 'cursor-pointer'}
                  />
                </g>
              )
            })}

            <defs>
              {patterns.map((biome, i) => {
                const idRef = id + '-' + biome.attributes.name
                //this is to create a different css defination for each hex fill so it does not get bugged in minimapview
                let sizeMultiplier = 1.2
                let hexSizeX = hexagonSize.x
                let hexSizeY = hexagonSize.y
                let hexSizeAdjY = hexagonSize.adjY
                let hexSizeAdjX = 0
                if (biome.attributes.layer == 2) {
                  hexSizeX = hexagonSize.x * sizeMultiplier
                  hexSizeY = hexagonSize.y * sizeMultiplier
                  hexSizeAdjY = hexagonSize.adjY * 2
                  hexSizeAdjX = -0.3
                }

                return (
                  <pattern
                    key={biome.attributes.name}
                    id={idRef}
                    width={hexSizeX}
                    height={hexSizeY}
                    patternUnits="objectBoundingBox"
                  >
                    <image
                      x={hexSizeAdjX}
                      y={hexSizeAdjY}
                      width={hexSizeX * 2}
                      height={hexSizeY * 2}
                      href={biome.attributes.image}
                    />
                  </pattern>
                )
              })}
            </defs>
          </g>
          <style>
            {`

            svg g {
              fill: transparent;
              fill-opacity: 1;
            }
            svg g:hover {
              fill: #7be3f6;
              fill-opacity: 0.7;
              /*cursor: pointer;*/
            }
            svg g:hover text {
              fill-opacity: 1;
            }
            
            svg g text {
              font-size: 0.12em;
              font-weight: 700;
              fill: black;
              fill-opacity: 0.7;
              transition: fill-opacity 0.5s;              
            }
            svg path {
              fill: none;
              stroke: #7be3f6;
              stroke-width: 0.2em;
              stroke-opacity: 0.7;
              stroke-linecap: round;
              stroke-linejoin: round;
            }
            
            svg path:hover {
              stroke: #4499a9;
              stroke-width: 0.2em;
              stroke-opacity: 1;
              /*cursor: pointer;*/
            }
            `}
          </style>
        </svg>
      )}
    </>
  )
}

export default RectMap
