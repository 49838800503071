import { items, sentinelClass, accessoriesAbilities } from './../config'

const Web3 = require('web3')
export const elvesAbi = require('./ABI/elves.json')
const mirenAbi = require('./ABI/miren.json')
const bridgeAbi = require('./ABI/bridge.json')
const campaignAbi = require('./ABI/campaigns.json')
const polyElvesAbi = require('./ABI/polyElves.json')
const polywalletAbi = require('./ABI/wallet.json')
const pRenAbi = require('./ABI/pMiren.json')
const moonAbi = require('./ABI/moon.json')
const slpAbi = require('./ABI/slp.json')
const orcsAbi = require('./ABI/orcs.json')
const orcsCastleAbi = require('./ABI/orcsCastle.json')
const artifactsAbi = require('./ABI/artifacts.json')
const eldersAbi = require('./ABI/elders.json')
const eldersIMAbi = require('./ABI/eldersIM.json')
const lootAbi = require('./ABI/loot.json')
const settlementsAbi = require('./ABI/settlements.json')

const { Multicall, ContractCallResults, ContractCallContext } = require('ethereum-multicall')

const alchemyethkey = process.env.REACT_APP_ALCHEMY_KEY
const etherscanKey = process.env.REACT_APP_ETHERSCAN_KEY
const polygonScanKey = process.env.REACT_APP_POLYGONSCAN_KEY
const polygonKey = process.env.REACT_APP_POLYGON_KEY

var api = require('etherscan-api').init(etherscanKey)

export const etherscan = 'etherscan' //"goerli.etherscan"

const blockExplorer = 'https://etherscan.io/tx/'
const blockExplorerPolygon = 'https://polygonscan.com/tx/'

const elvesContract = '0xA351B769A01B445C04AA1b8E6275e03ec05C1E75'
const mirenContract = '0xe6b055abb1c40b6c0bf3a4ae126b6b8dbe6c5f3f'
const bridgeContract = '0x15da62caae17bc3b526483897a14196c43377c72'
export const slpContract = '0x4E62635FA4714dbD6007DcdB24b2c9913E480883' // 0x036b85e1f14c7e5cf9e80b6dc51bccda38c09242
export const polyMirenContract = '0xA2eCFEBe618E90608882c4aD6b3a2eA6FdEB5e46'
export const polyMoonContract = '0x6C183674Cf5948508f1ABb75c4AF2CAA0b1a9d81'
const polyElvesContract = '0x4DeAb743F79b582c9b1d46b4aF61A69477185dd5'
const polybridgeContract = '0xb33506b63382622cede7a44e5c2b08c5cb9224b6'
export const polyWalletContact = '0x87e8fbe53fa6c1900bcb8511124c65467e0e71bb'
export const artifactsContract = '0xDb2E506d2863646C0141f77F2cE9f99bbbB6b8Ab'
export const eldersContract = '0xFb2B13c622D1590F9199f75D975574e8240B2618'
export const lootContract = '0x6290808fa7bfff922998c8e17856ec7832b49a31'
export const settlementsContract = '0xe77c955452cbBE0FfBd23e0f99e88D58ff6b1F14'
export const polyZugContract = '0xeb45921fedadf41df0bfcf5c33453acedda32441'

const mainnetChainId = '0x1'
const sideChainId = '0x89'

const campaignsContract = '0x367Dd3A23451B8Cc94F7EC1ecc5b3db3745D254e'
const orcsContractAddress = '0x3abedba3052845ce3f57818032bfa747cded3fca'
const orcsCastleContract = '0x2f3f840d17eb61020680c1f4b00510c3caa7df63'

export const web3 = new Web3(new Web3.providers.HttpProvider(alchemyethkey))
export const polyweb3 = new Web3(new Web3.providers.HttpProvider(polygonKey))

export const ethElves = new web3.eth.Contract(elvesAbi.abi, elvesContract)
export const ethMiren = new web3.eth.Contract(mirenAbi.abi, mirenContract)
export const ethArtifacts = new web3.eth.Contract(artifactsAbi.abi, artifactsContract)
export const ethElders = new web3.eth.Contract(eldersAbi.abi, eldersContract)
export const ethSettlements = new web3.eth.Contract(settlementsAbi.abi, settlementsContract)
export const gameContract = new web3.eth.Contract(campaignAbi.abi, campaignsContract)
export const prismBridge = new web3.eth.Contract(bridgeAbi.abi, bridgeContract)
export const polygonContract = new polyweb3.eth.Contract(polyElvesAbi.abi, polyElvesContract)
export const polygonPrismBridge = new polyweb3.eth.Contract(bridgeAbi.abi, bridgeContract)
export const polygonWallet = new polyweb3.eth.Contract(polywalletAbi.abi, polyWalletContact)
export const polyMiren = new polyweb3.eth.Contract(pRenAbi.abi, polyMirenContract)
export const polyMoon = new polyweb3.eth.Contract(moonAbi.abi, polyMoonContract)
export const polyZug = new polyweb3.eth.Contract(pRenAbi.abi, polyZugContract)
export const polySlp = new polyweb3.eth.Contract(slpAbi.abi, slpContract)
export const polyLoot = new polyweb3.eth.Contract(lootAbi.abi, lootContract)

export const orcsContract = new web3.eth.Contract(orcsAbi.abi, orcsContractAddress)
export const orcsCastle = new web3.eth.Contract(orcsCastleAbi, orcsCastleContract)

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

////////ETH FUNCTIONS////////////////////////////
export const txReceipt = async ({ txHash, interval }) => {
  module.exports = function getTransactionReceiptMined(txHash, interval) {
    const self = this
    const transactionReceiptAsync = function (resolve, reject) {
      self.getTransactionReceipt(txHash, (error, receipt) => {
        if (error) {
          reject(error)
        } else if (receipt == null) {
          setTimeout(() => transactionReceiptAsync(resolve, reject), interval ? interval : 500)
        } else {
          resolve(receipt)
        }
      })
    }

    if (Array.isArray(txHash)) {
      return Promise.all(txHash.map((oneTxHash) => self.getTransactionReceiptMined(oneTxHash, interval)))
    } else if (typeof txHash === 'string') {
      return new Promise(transactionReceiptAsync)
    } else {
      throw new Error('Invalid Type: ' + txHash)
    }
  }
}

export const awaitTxConfirmation = async ({ txHash, waitConfirmations }) => {
  let confirmations = 0
  let timeout = 0

  while (confirmations <= waitConfirmations) {
    // Waiting expectedBlockTime until the transaction is mined
    const trx = await polyweb3.eth.getTransaction(txHash)
    // Get current block number
    const currentBlock = await polyweb3.eth.getBlockNumber()
    confirmations = trx.blockNumber === null ? 0 : currentBlock - trx.blockNumber
    timeout += 1
    if (timeout > 15) {
      throw new Error('Transaction not confirmed in 45 seconds. Could be a network issue or high gas.')
    }
    await sleep(3000)
  }
  const txRcpt = await polyweb3.eth.getTransactionReceipt(txHash)
  if (txRcpt.status === 0 || txRcpt.status === false) {
    throw Error('Transaction failed')
  }
  return confirmations
}

///// setCurrentChain()
export const checkChain = async (requestedNetwork) => {
  const requestedChainId = requestedNetwork === 'ethereum' ? mainnetChainId : sideChainId
  let chainIdBool = false
  let connectedChainId

  if (window.ethereum) {
    if (window.ethereum.chainId === requestedChainId) {
      connectedChainId = window.ethereum.chainId
      chainIdBool = true
    }
  }

  return { chainIdBool, requestedChainId, connectedChainId }
}

export const lookupMultipleElves = async ({ array, chain }) => {
  let txArr = []

  let params = {
    abi: chain === 'eth' ? elvesAbi.abi : polyElvesAbi.abi,
    address: chain === 'eth' ? elvesContract : polyElvesContract,
    web3Instance: chain === 'eth' ? web3 : polyweb3,
  }

  if (array) {
    array.map((i, index) => {
      var tx = {
        reference: 'Elves' + i.toString(),
        contractAddress: params.address,
        abi: params.abi,
        calls: [
          { reference: 'elves' + i.toString(), methodName: 'elves', methodParameters: [i] },
          { reference: 'attributes' + i.toString(), methodName: 'attributes', methodParameters: [i] },
          { reference: 'ownerOfCall' + i.toString(), methodName: 'ownerOf', methodParameters: [i] },
          { reference: 'tokenURI' + i.toString(), methodName: 'tokenURI', methodParameters: [i] },
        ],
      }
      txArr.push(tx)
      return index
    })
  }

  let splitArray = []
  let results = []
  let arrayOfResults = []
  let i = 0
  while (i < txArr.length) {
    splitArray.push(txArr.slice(i, i + 25))
    i = i + 25
  }
  txArr = splitArray

  let j = 0

  while (j < txArr.length) {
    const multicall = new Multicall({ web3Instance: params.web3Instance, tryAggregate: true })
    const contractCallContext: ContractCallContext[] = txArr[j]
    const tempResults: ContractCallResults = await multicall.call(contractCallContext)
    arrayOfResults.push(tempResults)
    j++
  }

  //merge results in arrayOfResults into one array object with name results
  arrayOfResults.map((item, index) => {
    Object.keys(item.results).map((key, index) => {
      results[key] = item.results[key]
    })
  })
  results = { results: results }

  let elfObj
  let elfArry = []

  array.forEach((i) => {
    const elfAddress = results.results[`Elves${i}`].callsReturnContext[0].returnValues[0]
    const elfTimestamp = parseInt(results.results[`Elves${i}`].callsReturnContext[0].returnValues[1].hex, 16)
    const elfAction = parseInt(results.results[`Elves${i}`].callsReturnContext[0].returnValues[2].hex, 16)
    const elfHealthPoints = parseInt(results.results[`Elves${i}`].callsReturnContext[0].returnValues[3].hex, 16)
    const elfAttackPoints = parseInt(results.results[`Elves${i}`].callsReturnContext[0].returnValues[4].hex, 16)
    const elfPrimaryWeapon = parseInt(results.results[`Elves${i}`].callsReturnContext[0].returnValues[5].hex, 16)
    const elfLevel = parseInt(results.results[`Elves${i}`].callsReturnContext[0].returnValues[6].hex, 16)
    const elfOwnerOfAddress = results.results[`Elves${i}`].callsReturnContext[2].returnValues[0] //GOOD

    const elfHair = parseInt(results.results[`Elves${i}`].callsReturnContext[1].returnValues[0].hex, 16)
    const elfRace = parseInt(results.results[`Elves${i}`].callsReturnContext[1].returnValues[1].hex, 16)
    const elfAccessories = parseInt(results.results[`Elves${i}`].callsReturnContext[1].returnValues[2].hex, 16)
    const elfSentinelClass = parseInt(results.results[`Elves${i}`].callsReturnContext[1].returnValues[3].hex, 16)
    const elfWeaponTier = parseInt(results.results[`Elves${i}`].callsReturnContext[1].returnValues[4].hex, 16)
    const elfInventory = parseInt(results.results[`Elves${i}`].callsReturnContext[1].returnValues[5].hex, 16)

    let elfTokenData = results.results[`Elves${i}`].callsReturnContext[3].returnValues[0]
    let elfOwner = elfAddress
    let elfStatus = 'staked'

    var b
    var elfTokenObj
    try {
      b = elfTokenData.split(',')
      elfTokenObj = JSON.parse(atob(b[1]))
    } catch (error) {
      elfTokenObj = { image: null, name: null, body: null, helm: null, mainhand: null, offhand: null, attributes: null }
    }

    // console.log("CONTRACT ELFADDRESS: ", elfAddress)
    // console.log("OPENNSEA elfOwnerOfAddress: ", elfOwnerOfAddress)

    if (elfAddress === '0x0000000000000000000000000000000000000000') {
      elfOwner = elfOwnerOfAddress
      elfStatus = 'unstaked'
    }

    //compare elfTimestamp to current time to see if current time is greater, then varialbe is true
    let elfTime = false
    if (elfTimestamp > Math.floor(Date.now() / 1000)) {
      elfTime = true
    }

    let elfActionString
    //swtiches for the elf action
    switch (parseInt(elfAction)) {
      case 0:
        elfActionString = chain === 'eth' ? 'Idle' : 'Ready to Return'
        break
      case 1:
        elfActionString = 'Staked, but Idle'
        break
      case 2:
        elfActionString = elfTime ? 'On Campaign' : 'Campaign Ended'
        break
      case 3:
        elfActionString = 'Sent to Passive Campaign'
        break
      case 4:
        elfActionString = 'Returned from Passive Campaign'
        break
      case 5:
        elfActionString = 'Re-Rolled Weapon'
        break
      case 6:
        elfActionString = 'Re-Rolled Items'
        break
      case 7:
        elfActionString = elfTime ? 'Healing' : 'Done Healing'
        break
      case 8:
        elfActionString = chain === 'eth' ? 'Sent to Polygon' : elfTime ? 'On Eth Cooldown' : 'Idle'
        break
      case 9:
        elfActionString = 'Synergized'
        break
      case 10:
        elfActionString = 'Bloodthirst'
        break
      case 11:
        elfActionString = 'Rampage'
        break
      case 12:
        elfActionString = 'Bought Item'
        break
      case 13:
        elfActionString = 'Sold Item'
        break
      case 14:
        elfActionString = elfTime ? 'On Crusade' : 'Crusade Ended'
        break
      case 15:
        elfActionString = 'Returned from Crusade'
        break
      default:
        elfActionString = 'Unknown'
    }

    //elfAccessories

    const accessoriesImIndex = elfSentinelClass * 7 + elfAccessories + 1

    const accessoriesAbility = accessoriesAbilities.find((i) => i.id === accessoriesImIndex)
    const accessoriesName = accessoriesAbility ? accessoriesAbility.name : 'Unknown'
    const accessoriesAbilityString = accessoriesAbility ? accessoriesAbility.ability : 'Unknown'
    const accessoriesTier = accessoriesAbility ? accessoriesAbility.tier : 'Unknown'
    const accessoriesImage = accessoriesAbility ? accessoriesAbility.image : 'Unknown'

    let image = elfTokenObj.image
    if (accessoriesImIndex === 2 || accessoriesImIndex === 3) {
      image = accessoriesImage
    }

    elfObj = {
      owner: elfOwner.toLowerCase(),
      elfStatus: elfStatus,
      id: i,
      time: elfTimestamp,
      action: elfAction,
      actionString: elfActionString,
      level: elfLevel,
      image: image,
      name: elfTokenObj.name ? elfTokenObj.name : `Elf #${i}`,
      sentinelClass: elfSentinelClass,
      classString: sentinelClass[elfSentinelClass],
      race: elfRace,
      hair: elfHair,
      inventory: [elfInventory],
      inventoryImage: items[elfInventory].image,
      inventoryString: items[elfInventory].text,
      inventoryDescription: items[elfInventory].description,
      primaryWeapon: elfPrimaryWeapon,
      weaponTier: elfWeaponTier,
      attack: elfAttackPoints,
      accessories: elfAccessories,
      accessoriesName: accessoriesName,
      accessoriesAbility: accessoriesAbilityString,
      accessoriesTier: accessoriesTier,
      health: elfHealthPoints,
      attributes: elfTokenObj.attributes,
      chain: chain,
      cooldown: elfTime,
    }

    elfArry.push(elfObj)
  })

  return elfArry
}

////////////TX BUILDERs///////////////////

const txPayload = async (txData) => {
  const nonce = txData.web3
    ? await txData.web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest')
    : await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest') //get latest nonce
  //the transaction
  const tx = {
    from: window.ethereum.selectedAddress,
    to: txData.contract,
    nonce: nonce.toString(),
    data: txData.data,
    value: txData?.value ? txData.value : 0,
  }

  return tx
}

export const polyStatusMessage = (txHash) => {
  return (
    <>
      ✅ Check out your transaction on{' '}
      <a target="_blank" href={`${blockExplorerPolygon}${txHash}`} rel="noopener noreferrer">
        PolygonScan
      </a>{' '}
    </>
  )
}

const ethStatusMessage = (txHash) => {
  return (
    <>
      ✅ Check out your transaction on{' '}
      <a target="_blank" href={`${blockExplorer}${txHash}`} rel="noopener noreferrer">
        Etherscan
      </a>{' '}
    </>
  )
}

const sendTx = async (tx) => {
  //sign the transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx],
    })

    return {
      success: true,
      status: ethStatusMessage(txHash),
      txHash: txHash,
    }
  } catch (error) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message + ' Try reloading the page...',
    }
  }
}

const sendPolyTx = async (tx) => {
  //sign the transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx],
    })

    return {
      success: true,
      status: polyStatusMessage(txHash),
      txHash: txHash,
    }
  } catch (error) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message + ' Try reloading the page...',
    }
  }
}

///////////////////////////// WALLET FUNCTIONS ///////////////////////////

export const fetchTokenAllowance = async (tokenAddress, ownerAddress) => {
  const options = {
    method: 'POST',
    headers: { accept: 'application/json', 'content-type': 'application/json' },
    body: JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method: 'alchemy_getTokenAllowance',
      params: [
        {
          owner: ownerAddress,
          contract: tokenAddress,
          spender: polyWalletContact,
        },
      ],
    }),
  }

  try {
    const response = await fetch(polygonKey, options)
    const allowance = await response.json()
    if (parseInt(allowance.result / 1000000000000000000) > 200000) return true
    else return false
  } catch (e) {
    console.log(e)
    return false
  }
}

export const checkLootApproval = async (address) => {
  const res = await polyLoot.methods.isApprovedForAll(address, polyWalletContact).call()
  return res
}

export const approveWallet = async (params) => {
  const spender = polyWalletContact
  const amount = '10000000000000000000000000000000'
  let txParams

  if (params.token === 'ren') {
    txParams = {
      data: polyMiren.methods.approve(spender, amount).encodeABI(),
      contract: polyMirenContract,
      web3: polyweb3,
    }
  } else if (params.token === 'moon') {
    txParams = {
      data: polyMoon.methods.approve(spender, amount).encodeABI(),
      contract: polyMoonContract,
      web3: polyweb3,
    }
  } else if (params.token === 'slp') {
    txParams = {
      data: polySlp.methods.approve(spender, amount).encodeABI(),
      contract: slpContract,
      web3: polyweb3,
    }
  } else if (params.token === 'zug') {
    txParams = {
      data: polyZug.methods.approve(spender, amount).encodeABI(),
      contract: polyZugContract,
      web3: polyweb3,
    }
  } else {
    txParams = {
      data: polyLoot.methods.setApprovalForAll(spender, true).encodeABI(),
      contract: lootContract,
      web3: polyweb3,
    }
  }

  let tx = await txPayload(txParams)

  return await sendPolyTx(tx)
}

export const deposit = async (params) => {
  const amount = '10000000000000000000000000000000'
  let txParams

  if (params.token === 'ren') {
    txParams = {
      data: polygonWallet.methods.deposit(window.ethereum.selectedAddress, '100000000000000000000', 0).encodeABI(),
      contract: polyWalletContact,
      web3: polyweb3,
    }
  } /*
  else if(params.token === 'moon'){
     txParams = {    
      data: polyMoon.methods.approve(spender, amount).encodeABI(),
      contract: polyMoonContract,
      web3: polyweb3
      }
  }*/

  let tx = await txPayload(txParams)

  return await sendPolyTx(tx)
}

export const mintArtifacts = async (props) => {
  let response = await ethArtifacts.methods.usedSignatures(props.signature).call() //check if signature is already used
  if (parseInt(response) === 1) {
    return {
      success: false,
      status: '😥 Signature already used',
    }
  }
  let txParams = {
    data: ethArtifacts.methods.mint(props.qty, props.timestamp, props.signature).encodeABI(),
    contract: artifactsContract,
  }
  let tx = await txPayload(txParams)
  return await sendTx(tx)
}

export const mintElders = async (props) => {
  let txParams = {
    data: ethElders.methods.mint(props.qty).encodeABI(),
    contract: eldersContract,
  }
  let tx = await txPayload(txParams)
  return await sendTx(tx)
}

///SETTLEMENTS

export const unStakeSettlements = async ({ ids, signatures, authCodes }) => {
  let txParams = {
    data: ethSettlements.methods.unstake(ids, signatures, authCodes).encodeABI(),
    contract: settlementsContract,
  }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const stakeSettlements = async ({ ids }) => {
  let txParams = { data: ethSettlements.methods.stake(ids).encodeABI(), contract: settlementsContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const mintSettlements = async (props) => {
  let txParams = {
    data: ethSettlements.methods.mint(props.qty).encodeABI(),
    contract: settlementsContract,
  }
  let tx = await txPayload(txParams)
  return await sendTx(tx)
}

export const doActions = async (options) => {
  ///NOTE This function is work in progress. THIS DOES NOT WORK.
  let txParams = {
    data: ethElves.methods[options.functionName](options.params).encodeABI(),
    contract: elvesContract,
  }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const sendCampaign = async ({ tryTokenids, tryCampaign, trySection, tryWeapon, tryItem, useItem }) => {
  let txParams = {
    data: ethElves.methods.sendCampaign(tryTokenids, tryCampaign, trySection, tryWeapon, tryItem, useItem).encodeABI(),
    contract: elvesContract,
  }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const heal = async ({ healer, target }) => {
  let txParams = { data: ethElves.methods.heal(healer, target).encodeABI(), contract: elvesContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const sendPassive = async ({ ids }) => {
  let txParams = { data: ethElves.methods.passive(ids).encodeABI(), contract: elvesContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const returnPassive = async ({ ids }) => {
  let txParams = { data: ethElves.methods.returnPassive(ids).encodeABI(), contract: elvesContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const forging = async ({ ids }) => {
  let reRollPrice = 0.04 * 10 ** 18
  let hexString = reRollPrice.toString(16)

  let txParams = { data: ethElves.methods.forging(ids).encodeABI(), contract: elvesContract, value: hexString }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const merchant = async ({ ids }) => {
  let reRollPrice = 0.01 * 10 ** 18
  let hexString = reRollPrice.toString(16)

  let txParams = { data: ethElves.methods.merchant(ids).encodeABI(), contract: elvesContract, value: hexString }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const withdrawTokenBalance = async () => {
  let txParams = { data: ethElves.methods.withdrawTokenBalance().encodeABI(), contract: elvesContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const withdrawSomeTokenBalance = async ({ amount }) => {
  let txParams = { data: ethElves.methods.withdrawSomeTokenBalance(amount).encodeABI(), contract: elvesContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const checkIn = async (props) => {
  let txParams = {
    data: prismBridge.methods
      .checkIn(props.sentinelIds, props.eldersIds, props.artifacts, props.renAmount, props.owner, props.chain)
      .encodeABI(),
    contract: bridgeContract,
  }
  let tx = await txPayload(txParams)
  return await sendTx(tx)
}

export const checkOut = async (props) => {
  let txParams = {
    data: prismBridge.methods
      .checkOutSentinel(props.ids, props.sentinel, props.signature, props.authCode, props.owner, props.chain)
      .encodeABI(),
    contract: bridgeContract,
  }
  let tx = await txPayload(txParams)
  return await sendTx(tx)
}

export const transferTokensIn = async (props) => {
  let txParams = {
    data: prismBridge.methods
      .transferTokensIn(
        props.tokenAmount,
        props.tokenIndex,
        props.signedTransaction,
        props.timestamp,
        props.checkSumAddress,
        props.chain,
      )
      .encodeABI(),
    contract: bridgeContract,
  }
  let tx = await txPayload(txParams)
  return await sendTx(tx)
}

export const checkOutRen = async (props) => {
  let tx = await txPayload(ethElves.methods.checkOutRen(props.renAmount, props.signature, props.timestamp).encodeABI())

  try {
    const txHash = await window.ethereum.request({ method: 'eth_sendTransaction', params: [tx] })

    let transactionReceipt = null

    while (transactionReceipt == null) {
      // Waiting expectedBlockTime until the transaction is mined
      transactionReceipt = await web3.eth.getTransactionReceipt(txHash)
      await sleep(12000)
    }

    return {
      success: true,
      status: ethStatusMessage(txHash),
      txHash: txHash,
      receipt: transactionReceipt,
    }
  } catch (error) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message + ' Try reloading the page...',
    }
  }
}

export const getCampaign = async (id, chain) => {
  let response //= await gameContract.methods.camps(id).call()

  if (chain === 'polygon') {
    response = await polygonContract.methods.camps(id).call()
  } else {
    response = await gameContract.methods.camps(id).call()
  }

  let campaignObj = {
    id: id,
    baseRewads: response[0],
    creatureCount: response[1],
    creatureHealth: response[2],
    expPoints: response[3],
    minLevel: response[4],
    items: response[5],
    weapons: response[6],
  }

  return campaignObj
}

export const getPawnItems = async (id) => {
  let response = await polygonContract.methods.pawnItems(id).call()

  let pawnItemSupply = {
    id: id,
    buyPrice: response[0],
    sellPrice: response[1],
    maxSupply: response[2],
    currentInventory: response[3],
    image: items[id].image,
    inventoryString: items[id].text,
    inventoryDescription: items[id].description,
  }

  return pawnItemSupply
}

///////////Helpers/////////////////////////

export const getEthGasPrice = async () => {
  const gasApi = await fetch(`https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${etherscanKey}`, {
    method: 'GET',
  })
  const response = await gasApi.json()

  return response.result.SafeGasPrice
}

export const getPolygonGasPrice = async () => {
  const gasApi = await fetch(
    `https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=${polygonScanKey}`,
    {
      method: 'GET',
    },
  )
  const r = await gasApi.json()
  return r.result.SafeGasPrice
}

export async function getContractPrice() {
  const res = await ethElves.methods.getElf().call()
  return web3.utils.fromWei(res)
}

export async function getElf(id) {
  const res = await ethElves.methods.elves(id).call()
  return res
}

export const tokensByOwner = async (address) => {
  var supply = ethElves.methods.tokensByOwner(address).call()
  return supply
}

export const ownerOf = async (token) => {
  var address = ethElves.methods.ownerOf(token).call()
  return address
}

export const getTokenSupply = async () => {
  var supply = ethElves.methods.totalSupply().call()
  return supply
}

export const getMintPriceLevel = async () => {
  var supply = ethElves.methods.getMintPriceLevel().call()
  return supply
}

export const fetchSlpData = async () => {
  const swapRate = await polygonWallet.methods.getSlpSwapRate().call()
  const mooonLeft = await polygonWallet.methods.moonForLPsLeft().call()
  return { swapRate: swapRate, mooonLeft: mooonLeft }
}

export const fetchScrollsData = async () => {
  const scrollsLeft = await polygonContract.methods.scrollsForSale().call()
  const scrollsPrice = await polygonContract.methods.scrollsForSalePrice().call()
  return { scrollsLeft: scrollsLeft, scrollsPrice: scrollsPrice }
}

export const balanceOf = async (address) => {
  let ethWalletRen = 0 //miren
  let ethGameRen = 0 //contractRen
  let polyGameRen = 0 //polyMiren
  let polyGameMoon = 0 //moon
  let polyWalletRen = 0 //polyRenWallet
  let polyWalletMoon = 0 //moonWallet
  let polyWalletSlp = 0 //moonWallet

  let artifacts
  let scrolls

  try {
    polyWalletRen = await polyMiren.methods.balanceOf(address).call()
  } catch (e) {
    console.log(e)
  }
  try {
    polyWalletMoon = await polyMoon.methods.balanceOf(address).call()
  } catch (e) {
    console.log(e)
  }
  try {
    polyWalletSlp = await polySlp.methods.balanceOf(address).call()
  } catch (e) {
    console.log(e)
  }

  try {
    ethWalletRen = await ethMiren.methods.balanceOf(address).call()
  } catch (e) {
    console.log(e)
  }
  try {
    ethGameRen = await ethElves.methods.bankBalances(address).call()
  } catch (e) {
    console.log(e)
  }
  try {
    const allBalances = await polygonContract.methods.getAllAccountBalances(address).call()
    polyGameRen = allBalances[0]
    polyGameMoon = allBalances[1]
    artifacts = allBalances[2]
    scrolls = allBalances[3]
  } catch (e) {
    console.log(e)
  }

  let balances = {
    ethWalletRen: ethWalletRen,
    ethGameRen: ethGameRen,
    polyGameRen: polyGameRen,
    polyWalletRen: polyWalletRen,
    polyWalletMoon: polyWalletMoon,
    polyGameMoon: polyGameMoon,
    artifacts: artifacts,
    scrolls: scrolls,
    polyWalletSlp: polyWalletSlp,
  }
  return balances
}
export const usedRenSignatures = async (signature) => {
  const usedIndex = await ethElves.methods.usedRenSignatures(signature).call()
  return usedIndex
}

export const getEthPrice = async () => {
  var price = api.stats.ethprice()
  return price
}

export const getTxReceipt = async (txHash) => {
  var ret = api.proxy.eth_getTransactionReceipt(txHash)
  return ret
}

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
      })
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: '',
        }
      } else {
        return {
          address: '',
          status: '🦊 Connect to Metamask.',
        }
      }
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message,
      }
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://metamask.app.link/dapp/app.ethernalelves.com/`}
              className="link"
            >
              You must install Metamask, a virtual Ethereum wallet, in your browser.
            </a>
          </p>
        </span>
      ),
    }
  }
}

/////POLYGON FUNCTIONS////

export const getCreatureHealth = async () => {
  return await polygonContract.methods.CREATURE_HEALTH().call()
}

export const getRampages = async (id) => {
  let response //= await gameContract.methods.camps(id).call()

  response = await polygonContract.methods.rampages(id).call()

  let rampageObj = {
    id: id,
    probDown: response[0],
    probSame: response[1],
    probUp: response[2],
    levelsGained: response[3],
    minLevel: response[4],
    maxLevel: response[5],
    renCost: response[6],
    count: response[7],
  }

  return rampageObj
}

/////////////////ELDERS//////////////////////////////

export const unStakeElder = async ({ ids, elderDna, signatures, authCodes }) => {
  let txParams = {
    data: ethElders.methods.unstake(ids, elderDna, signatures, authCodes).encodeABI(),
    contract: eldersContract,
  }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const stakeElder = async ({ ids }) => {
  let txParams = { data: ethElders.methods.stake(ids).encodeABI(), contract: eldersContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

/////////////////NEW STAKE AND UNSTAKE FOR SENTINELS

export const stake = async ({ ids }) => {
  let txParams = { data: ethElves.methods.stake(ids).encodeABI(), contract: elvesContract }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}

export const unStake = async ({ ids, sentinelDna, signatures, authCodes }) => {
  let txParams = {
    data: ethElves.methods.unstake(ids, sentinelDna, signatures, authCodes).encodeABI(),
    contract: elvesContract,
  }
  let tx = await txPayload(txParams)

  return await sendTx(tx)
}
