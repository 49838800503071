import React, { useEffect } from 'react'
import elvesIcon from '../../assets/images/icons/sentinels.png'
import useSound from 'use-sound'
import audioSprite from '../../assets/sounds/audioSprite.mp3'

import { useMoralis } from 'react-moralis'

const AlertBar = ({ alert, setAlert }) => {
  //setAlert({show: false}) after 5 seconds
  const { user } = useMoralis()

  const [play, { stop }] = useSound(audioSprite, {
    volume: 0.1,
    soundEnabled: user?.attributes?.ownerSfxPref,
    sprite: {
      popup: [460, 1245],
    },
  })

  useEffect(() => {
    if (alert.show) {
      play({ id: 'popup' })
      setTimeout(() => {
        setAlert({ show: false })
      }, 7000)
    }
  }, [alert.show])

  return (
    alert.show && (
      <div
        className="
      pt-4
      md:pt-10 
      fade-in
      text-center justify-center content-center items-center 
      z-50
      absolute bottom-0 md:left-0 right-0
      h-64
      w-screen
      bg-cover lg:bg-contain bg-center bg-no-repeat bg-pop_up_window_A3
      flex flex-col
      "
      >
        <span className="uppercase p-2 max-w-screen-md">{alert.value.content}</span>
        <span
          className="btn-menu text-base font-bold uppercase px-16 py-2 cursor-pointer font-app text-center"
          onClick={() => setAlert({ show: false })}
        >
          close
        </span>
        {/*<pre>{alert.value.content}</pre>
        .replaceAll("\\.\\d+$", "")
        */}
      </div>
    )
  )
}

export default AlertBar
