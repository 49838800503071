import React from 'react'

import './style.css'
import { accessoriesAbilities, items } from '../../config'
import OpenSeaIcon from './OpenSeaIcon'
import MaticIcon from './MaticIcon'
import EthIcon from './EthIcon'

const ElfCard = ({ data }) => {
  const token_id = data.get('token_id')
  const image = data.get('elf_image')
  const weaponTier = data.get('elf_weaponTier')
  const level = data.get('elf_level')
  const accessories = data.get('elf_accessories')
  const owner = data.get('owner_of')
  const ownerTruncated = owner.slice(0, 2) + '...' + owner.slice(-4)
  const inventory = parseInt(data.get('elf_inventory'))

  const chainIcon = data.get('chain') === 'eth' ? <EthIcon /> : <MaticIcon />

  var accessoryTier
  accessoriesAbilities.forEach((item) => {
    if (item.name === accessories) {
      accessoryTier = item.tier
    }
  })

  var inventoryString
  items.forEach((item) => {
    if (item.item === inventory) {
      inventoryString = item.text
    }
  })

  return (
    <div className="character-card-elfcard">
      <div className="flex justify-between text-sm" style={{ width: 180, height: 25 }}>
        <span>#{token_id}</span>
        {chainIcon}
        <a
          target="_blank"
          href={'https://opensea.io/assets/0xa351b769a01b445c04aa1b8e6275e03ec05c1e75/'.concat(token_id)}
        >
          <OpenSeaIcon />
        </a>
      </div>
      <img className="character-image-elfcard" src={image} />
      <div className="flex justify-between text-sm items-center mt-1">
        <span className="props-title-elfcard">Level</span>
        <span className="props-value-elfcard">{level}</span>
      </div>
      <div className="flex justify-between text-sm items-center mt-1">
        <span className="props-title-elfcard">weapon</span>
        <span className="props-value-elfcard">+{weaponTier}</span>
      </div>
      <div className="flex justify-between text-sm items-center mt-1">
        <span className="props-title-elfcard">accessory</span>
        <span className="props-value-elfcard">T{accessoryTier}</span>
      </div>
      <div className="flex justify-between text-sm items-center mt-1">
        <span className="props-title-elfcard">item</span>
        <span className="props-value-elfcard">{inventoryString}</span>
      </div>
      <div className="flex justify-between text-sm items-center mt-1">
        <span className="props-title-elfcard">owner</span>
        <span className="props-value-elfcard">
          <a className="elf-card-property-a" target="_blank" href={`https://etherscan.io/address/${owner}`}>
            {ownerTruncated}
          </a>
        </span>
      </div>
    </div>
  )
}

export default ElfCard
